import React, {FormEvent, useState} from 'react'
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack
} from '@mui/material'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import EmployeeToSHL from 'src/entities/EmployeeToSHL'
import ExaminationType from 'src/entities/ExaminationType'
import CuiDialogTitle from 'src/components/custom/CuiDialogTitle'

interface ParticipantModalProps {
  employeeToShl?: EmployeeToSHL
  setEmployeeToShl: React.Dispatch<
    React.SetStateAction<EmployeeToSHL | undefined>
  >
  isOpenModal: boolean
  onCancelClick: () => void
  onSaveClick: () => Promise<void>
  levelList: ExaminationType[]
}

export default function ParticipantModal({
  employeeToShl,
  setEmployeeToShl,
  isOpenModal,
  onCancelClick,
  onSaveClick,
  levelList
}: ParticipantModalProps) {
  const [loading, setLoading] = useState<boolean>()
  const [selectedValue, setSelectedValue] = useState(employeeToShl)
  if (!employeeToShl) {
    return <></>
  }

  const submitForm = async (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault()
    setLoading(true)
    await onSaveClick()
    setLoading(false)
  }

  return (
    <Dialog open={isOpenModal} fullWidth>
      <CuiDialogTitle title="Edit participant" onCancelClick={onCancelClick} />
      <DialogContent>
        <Stack spacing={3} mt={1}>
          <form onSubmit={submitForm}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Box
                  paddingTop={1.75}
                  paddingBottom={3}
                  component={'span'}
                  sx={{display: 'flex', alignItems: 'center'}}
                >
                  <Avatar
                    sx={{
                      fontSize: '0.875rem',
                      mr: 1,
                      backgroundColor: employeeToShl.color,
                      color: '#fff'
                    }}
                    component={'span'}
                  >
                    {employeeToShl.firstName.charAt(0)}
                    {employeeToShl.lastName.charAt(0)}
                  </Avatar>
                  {`${employeeToShl.firstName} ${employeeToShl.lastName}`}
                </Box>
              </Grid>
              <Grid item xs={12} pb={1}>
                <FormControl fullWidth>
                  <InputLabel id="track-select-label">Track</InputLabel>
                  <Select
                    required
                    labelId="track-select-label"
                    id="track-select"
                    value={
                      selectedValue?.examinationTypeID ||
                      employeeToShl.examinationTypeID
                    }
                    label="Track"
                    onChange={({target}) => {
                      const selectedLevel = levelList.find(
                        e => e.examinationTypeID === +target.value
                      )
                      if (!selectedLevel) return
                      setEmployeeToShl(prev =>
                        prev
                          ? {
                              ...prev,
                              examinationTypeID: +target.value,
                              examinationType: selectedLevel,
                              examinationTypeShortName:
                                selectedLevel.examinationTypeShortName
                            }
                          : undefined
                      )

                      setSelectedValue({
                        ...employeeToShl,
                        examinationTypeID: +target.value,
                        examinationType: selectedLevel,
                        examinationTypeShortName:
                          selectedLevel.examinationTypeShortName
                      })
                    }}
                    input={<OutlinedInput label="Track" />}
                    sx={{
                      '#track-select': {
                        display: 'flex',
                        alignItems: 'center',
                        py: 1,
                        minHeight: 40
                      }
                    }}
                  >
                    {levelList?.map(r => (
                      <MenuItem
                        key={r.examinationTypeID}
                        value={r.examinationTypeID}
                        sx={{display: 'flex'}}
                      >
                        <ListItemText primary={r.examinationTypeShortName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <DialogActions sx={{padding: '24px 0 0 0!important'}}>
              <CuiCancelSaveButton
                onCancel={onCancelClick}
                loading={loading}
                typeSaveButton="submit"
              />
            </DialogActions>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
