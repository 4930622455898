import React, {useCallback, useEffect, useState} from 'react'
import {Link, Route, Routes, useParams} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {Box, Stack, Tab, Tabs, Typography} from '@mui/material'
import config from 'src/config'
import {useRouteMatch} from 'src/utils/routeHelper'
import {useAuth} from 'src/contexts/Auth'
import {EmployeeInfo} from 'src/entities/Employee'
import UserInfoPage from 'src/components/shl/participants/userProfil/UserInfo'
import UserTestsPage from 'src/components/shl/participants/userProfil/UserTests'
import {
  ExaminToEmpType,
  SubmissionStatusEnum
} from 'src/entities/EmployeeToExaminationCycleType'

export default function UserPage() {
  const {userId} = useParams()
  const {type} = useParams()
  const [employee, setEmployee] = useState<EmployeeInfo>()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const routeMatch = useRouteMatch([
    '/shl/participants/:type/user/:userId/userInfo',
    '/shl/participants/:type/user/:userId/tests'
  ])

  const [examinationCycleList, setExaminationCycleList] = useState<
    ExaminToEmpType[]
  >([])
  const [examinationOnTime, setEexaminationOnTime] = useState<number>(0)

  const [examinationLate, setEexaminationLate] = useState<number>(0)

  const currentTab = routeMatch?.pattern?.path

  const getParticipants = useCallback(() => {
    fetchWithUser(config.apiUrl + `/Employee/GetEmployeeInfo/${userId}`)
      .then(res => res.json())
      .then((data: EmployeeInfo) => {
        setEmployee(data)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again', {
          variant: 'error'
        })
        throw e
      })
  }, [fetchWithUser, enqueueSnackbar, userId])

  useEffect(getParticipants, [getParticipants])

  const GetExaminationCycleList = useCallback(() => {
    fetchWithUser(
      `${
        config.apiUrl
      }/Examination/GetExaminationListByEmpId?empId=${userId}&isActiveEmp=${
        type === 'active'
      }&isBackoffice=true`
    )
      .then(res => res.json())
      .then((data: ExaminToEmpType[]) => {
        const filteredArray: ExaminToEmpType[] = data.filter(
          obj =>
            obj?.submissionStatus &&
            obj.submissionStatus < SubmissionStatusEnum.Lack
        )
        setExaminationCycleList(filteredArray)

        const countOnTime = filteredArray.reduce(
          (accumulator, obj) =>
            accumulator +
            +(obj.submissionStatus === SubmissionStatusEnum.OnTime),
          0
        )
        const countLate = filteredArray.reduce(
          (accumulator, obj) =>
            accumulator + +(obj.submissionStatus === SubmissionStatusEnum.Late),
          0
        )

        setEexaminationOnTime(countOnTime)
        setEexaminationLate(countLate)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again', {
          variant: 'error'
        })
        throw e
      })
  }, [fetchWithUser, enqueueSnackbar, userId, type])

  useEffect(GetExaminationCycleList, [GetExaminationCycleList])

  return (
    <Box sx={{width: '100%', height: '100%', px: 8}}>
      <Stack direction="row" alignItems="baseline" spacing={5}>
        <Typography fontWeight="bold" fontSize="x-large">
          {employee?.firstName} {employee?.lastName} {employee?.employeeID}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        alignItems="end"
        justifyContent="space-between"
        pr={2}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="end"
          justifyContent="space-between"
          pr={2}
        >
          <Tabs value={currentTab}>
            <Tab
              label="userInfo"
              value="/shl/participants/:type/user/:userId/userInfo"
              to={`/shl/participants/${type}/user/${userId}/userInfo`}
              component={Link}
            />
            <Tab
              label="tests"
              value="/shl/participants/:type/user/:userId/tests"
              to={`/shl/participants/${type}/user/${userId}/tests`}
              component={Link}
              iconPosition="end"
            />
          </Tabs>
        </Stack>
      </Stack>
      <Routes>
        <Route
          path="/userInfo"
          element={
            employee ? (
              <UserInfoPage
                participant={employee}
                examinationList={examinationCycleList}
                examinationLate={examinationLate}
                examinationOnTime={examinationOnTime}
              />
            ) : (
              <></>
            )
          }
        />
        <Route
          path="/tests"
          element={
            <UserTestsPage
              examinationList={examinationCycleList}
              examinationLate={examinationLate}
              examinationOnTime={examinationOnTime}
            />
          }
        />
      </Routes>
    </Box>
  )
}
