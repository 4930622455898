// routes
import Router from 'src/routes'
// theme
import ThemeProvider from 'src/theme'
// components
import {LicenseInfo} from '@mui/x-data-grid-pro'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import MotionLazyContainer from 'src/components/animate/MotionLazyContainer'
import {AuthContextProvider} from 'src/contexts/Auth'
import NotistackProvider from 'src/components/NotistackProvider'
import config from 'src/config'

// ----------------------------------------------------------------------

LicenseInfo.setLicenseKey(config.MUIDataGridLicenseKey)
export default function App() {
  return (
    <AuthContextProvider>
      <MotionLazyContainer>
        <ThemeProvider>
          <NotistackProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router />
            </LocalizationProvider>
          </NotistackProvider>
        </ThemeProvider>
      </MotionLazyContainer>
    </AuthContextProvider>
  )
}
