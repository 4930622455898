import {useCallback, useEffect, useState} from 'react'
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import {DateTimePicker} from '@mui/x-date-pickers'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import CuiDialogTitle from 'src/components/custom/CuiDialogTitle'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import {useSnackbar} from 'notistack'
import {fDateWithYear} from 'src/utils/formatTime'
import SHLCycle from 'src/entities/SHLCycle'

interface EventModalProps {
  closeModal: () => void
  getCurrentSHLCycleDate: () => void
  shlCurrentCycle?: SHLCycle
  firstDateInCycle: Date
  isOpen: boolean
}

export default function GenerateCalanderModal({
  closeModal,
  shlCurrentCycle,
  isOpen,
  firstDateInCycle,
  getCurrentSHLCycleDate
}: EventModalProps) {
  const [loading, setLOading] = useState<boolean>()
  const [showChip, setShowChip] = useState<boolean>(true)
  const [showWarning, setShowWarning] = useState<boolean>(true)
  const [startDate, setStartDate] = useState<Date>(new Date())
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const modalStatus =
    !!shlCurrentCycle && shlCurrentCycle.endDate > new Date()
      ? 'edit'
      : 'generate'

  const deleteCalendar = async () => {
    return fetchWithUser(
      config.apiUrl + `/Calendar/DeleteCalendar/${shlCurrentCycle!.shlCycleID}`,
      {
        method: 'Delete',
        headers: {'Content-Type': 'application/json'}
      }
    )
      .then(res => {
        res.json()
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }

  const generateCalendar = async () => {
    const startDate1 = fDateWithYear(startDate)
    return fetchWithUser(
      config.apiUrl + `/Calendar/GenerateCalander?startDate=${startDate1}`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'}
      }
    )
      .then(res => res.json())
      .then(() => {
        console.log('res')
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }

  const isSameAsClosestWednesday = useCallback(() => {
    const dayOfWeek = startDate.getDay()
    const Wednesday = 3
    const daysToWednesday = (Wednesday - dayOfWeek + 7) % 7
    const closestWednesday = new Date(startDate)
    return (
      new Date(
        closestWednesday.setDate(startDate.getDate() + daysToWednesday)
      ).getDate() === firstDateInCycle.getDate()
    )
  }, [firstDateInCycle, startDate])

  useEffect(() => {
    setShowWarning(isSameAsClosestWednesday())
  }, [startDate, isSameAsClosestWednesday])

  const saveNewCycle = async () => {
    setLOading(true)
    if (modalStatus === 'edit') await deleteCalendar()
    generateCalendar()
      .then(() => getCurrentSHLCycleDate())
      .then(() => closeModal())
  }

  return (
    <Dialog open={isOpen} fullWidth>
      <CuiDialogTitle
        title={`${modalStatus} Calendar`}
        onCancelClick={closeModal}
      />
      <DialogContent>
        {showChip && (
          <Chip
            label="The cycle will automatically start on the closest Wednesday to your choice"
            variant="filled"
            onDelete={() => setShowChip(false)}
          />
        )}
        <Stack mt={2}>
          <DateTimePicker
            views={['year', 'month', 'day']}
            renderInput={props => <TextField {...props} fullWidth required />}
            label="Date"
            value={startDate}
            inputFormat="dd/MM/yyyy"
            onChange={newValue => {
              setStartDate(newValue as Date)
            }}
          />
          {showWarning && (
            <Typography variant="body2" color={'#ff9800'}>
              The selected date is the same as the start date of the existed
              cycle
            </Typography>
          )}
          <DialogActions sx={{padding: '24px 0 0 0!important', marginTop: 5}}>
            <CuiCancelSaveButton
              onCancel={closeModal}
              onSave={saveNewCycle}
              loading={loading}
              typeSaveButton="submit"
              disableSave={loading}
              disableCancel={loading}
            />
          </DialogActions>
        </Stack>
        {loading && (
          <Typography variant="subtitle2" color={'#4caf50'} textAlign="center">
            We are generating a new calendar for you, thanks for your patience
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  )
}
