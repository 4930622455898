export default interface ExaminationType {
  examinationTypeID: number
  examinationTypeName: string
  examinationTypeShortName: string
  isOpenBook?: boolean
  isOpenQuestions?: boolean
  isActive: boolean
  participantsNumber: number
  languageID?: Language
  reviewers: number[]
  reviewerNames?: string[]
}
export enum Language {
  ENG = 1,
  HEB
}
