import React, {Dispatch} from 'react'
import {useParams} from 'react-router'
import {Stack} from '@mui/material'
import {
  GridColumnVisibilityModel,
  GridExportStateParams,
  GridFilterModel,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro'
import {GridInitialStatePro} from '@mui/x-data-grid-pro/models/gridStatePro'
import ExaminationType from 'src/entities/ExaminationType'
import {ParticipantsTypeEnum} from 'src/components/shl/participants/participantsUtils'
import FilterToolbar from 'src/components/shl/shared/FilterToolbar'
import CuiToolbar from 'src/components/custom/CuiToolbar'

interface ParticipantsToolbarProps {
  participantsType: ParticipantsTypeEnum
  trackList: ExaminationType[]
  selectedTrackName: string
  setFilterModel: Dispatch<React.SetStateAction<GridFilterModel>>
  columnVisibilityModel?: GridColumnVisibilityModel
  exportState: (params?: GridExportStateParams) => GridInitialStatePro
}
export default function ParticipantsToolbar({
  participantsType,
  trackList,
  selectedTrackName,
  setFilterModel,
  columnVisibilityModel,
  exportState
}: ParticipantsToolbarProps) {
  const {type} = useParams()

  return (
    <Stack>
      <CuiToolbar
        screenId={participantsType}
        columnVisibilityModel={columnVisibilityModel}
        exportState={exportState}
        csvFileName={`shlParticpants${selectedTrackName}`}
      />
      <Stack direction="row" width="100%" px={2} pb={1} mt={1} spacing={1}>
        {participantsType !== ParticipantsTypeEnum.Invited ? (
          <FilterToolbar
            trackList={trackList}
            selectedTrackName={selectedTrackName}
            setFilterModel={setFilterModel}
            urlForChangeModel={`/shl/participants/${type}`}
            placeholderSearch="Search participant"
          />
        ) : (
          <GridToolbarQuickFilter
            placeholder="Search participant"
            variant="outlined"
            fullWidth
            sx={{
              height: 52,
              '.MuiFormControl-root': {
                height: '100%'
              },
              '.MuiOutlinedInput-root': {
                height: '100%'
              }
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}
