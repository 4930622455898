import {format, getTime, formatDistanceToNow, isValid} from 'date-fns'
import {toJewishDate, formatJewishDateInHebrew} from 'jewish-date'

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy')
}

export function fDateWithYear(date: Date | string | number) {
  if (!date) {
    return ''
  }
  date = new Date(date)

  if (!isValid(date)) {
    return ''
  }
  return format(date, 'yyyy-MM-dd')
}

export function fDateAddDays(date: Date | string | number, number: number) {
  date = new Date(date)
  date.setDate(date.getDate() + number)
  return format(date, 'dd/MM/yyyy')
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date))
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm')
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  })
}

export function getLocalDateFromUtc(date: Date) {
  return date.toString().endsWith('Z') ? new Date(date) : new Date(date + 'z')
}

export function getLocalDateFromString(date?: string) {
  return date ? getLocalDateFromUtc(new Date(date)) : undefined
}

export function heDate(date: Date) {
  return formatJewishDateInHebrew(toJewishDate(date))
}
