import React, {FormEvent, useEffect, useState} from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField
} from '@mui/material'
import {DateTimePicker} from '@mui/x-date-pickers'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import CalendarEvent from 'src/entities/CalendarEvent'
import CuiDialogTitle from 'src/components/custom/CuiDialogTitle'
import {getLocalDateFromUtc} from 'src/utils/formatTime'

interface EventModalProps {
  onCancel: () => void
  isOpen: boolean
  event: CalendarEvent | undefined
  editEvent: (eventData: CalendarEvent) => Promise<void> | undefined
}

export default function EventModal({
  onCancel,
  isOpen,
  event,
  editEvent
}: EventModalProps) {
  const [eventForm, setEventForm] = useState<CalendarEvent>({} as CalendarEvent)
  const [loading, setLoading] = useState<boolean>()
  useEffect(() => {
    event && setEventForm(event)
  }, [event])

  const submitForm = async (form: FormEvent<HTMLFormElement>) => {
    console.log('form', form)
    form.preventDefault()
    setLoading(true)
    const body: CalendarEvent = {...eventForm}
    var eventData = {
      id: body.sourceId,
      sourceId: body.sourceId,
      title: body.title,
      start: getLocalDateFromUtc(body.start),
      calendarEventType: body.calendarEventType
    }
    if (editEvent) await editEvent(eventData)
    onCancel()
    setLoading(false)
  }

  return (
    <Dialog open={isOpen} fullWidth>
      <CuiDialogTitle title={'Update Event'} onCancelClick={onCancel} />
      <DialogContent>
        <Stack mt={1}>
          <form onSubmit={submitForm}>
            <Grid container height={'medium'} rowSpacing={3}>
              <Grid item xs={12}>
                <TextField
                  name="Event Name"
                  label="Event Name"
                  fullWidth
                  value={eventForm.title ?? ''}
                  required
                  InputProps={{readOnly: true}}
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  views={['year', 'month', 'day']}
                  renderInput={props => (
                    <TextField {...props} fullWidth required />
                  )}
                  label="Date"
                  value={eventForm.start ?? new Date()}
                  inputFormat="dd/MM/yyyy"
                  onChange={newValue => {
                    setEventForm(prev => ({
                      ...prev,
                      start: newValue ?? new Date()
                    }))
                  }}
                />
              </Grid>
            </Grid>
            <DialogActions sx={{padding: '24px 0 0 0!important', marginTop: 5}}>
              <CuiCancelSaveButton
                onCancel={onCancel}
                loading={loading}
                typeSaveButton="submit"
                disableSave={loading}
                disableCancel={loading}
              />
            </DialogActions>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
