export enum CalendarEventTypeEnum {
  Lottery = 1,
  Examination = 2,
  ExaminationReminder1 = 3,
  ExaminationReminder2 = 4,
  HalachaEmail = 5,
  ExaminationPublish = 6,
  dailyUnit = 7
}

export default interface CalendarEvent {
  id: number
  sourceId: number
  calendarEventType?: CalendarEventTypeEnum
  shlCycleId?: number
  dayInUnit?: number
  start: Date
  title: string
}

export interface CalendarEventExtended extends CalendarEvent {
  end: Date
  backgroundColor: string
  allDay: boolean
  sourceId: number
}
