import React, {MouseEventHandler} from 'react'
import {IconButton, SxProps, Theme} from '@mui/material'
import SvgIconStyle from 'src/components/SvgIconStyle'

interface EditIconButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>
  sx?: SxProps<Theme>
  disabled?: boolean
}

export default function EditIconButton({
  onClick,
  sx,
  disabled
}: EditIconButtonProps) {
  return (
    <IconButton onClick={onClick} sx={sx} disabled={disabled}>
      <SvgIconStyle
        src="/assets/icons/ic_edit.svg"
        sx={{
          color: '#637381',
          width: 20
        }}
      />
    </IconButton>
  )
}
