import React, {useState, useCallback, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import {useSnackbar} from 'notistack'
import interactionPlugin from '@fullcalendar/interaction'
import {
  Button,
  Stack,
  CircularProgress,
  Backdrop,
  Typography
} from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import EditCalendarIcon from '@mui/icons-material/EditCalendar'
import Swal, {SweetAlertOptions} from 'sweetalert2'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'
import {getLocalDateFromUtc, fDateWithYear} from 'src/utils/formatTime'
import EventModal from 'src/components/shl/calander/EventModal'
import CalendarEvent, {
  CalendarEventExtended,
  CalendarEventTypeEnum
} from 'src/entities/CalendarEvent'
import GeneratetCalanderModal from 'src/components/shl/calander/GenerateCalanderModal'
import SHLCycle from 'src/entities/SHLCycle'

const eventBackgroundColor = [
  '',
  '#d6f5cd',
  '#c8e5ff',
  '#fff0c4',
  ' #ffd9c4',
  'lightblue',
  '#c8fffc',
  'white'
]

function RenderEventContent(eventInfo: any) {
  eventInfo.textColor = 'black'
  eventInfo.isEndResizable = false
  eventInfo.isStartResizable = false
  return (
    <div style={{border: 'none'}} id={'event'}>
      <i
        style={{
          color: eventInfo.textColor,

          direction: 'rtl',
          border: 'none'
        }}
      >
        {eventInfo.event._def.title}
      </i>
    </div>
  )
}

export default function CalendarPage() {
  const [currentEvents, setCurrentEvents] = useState<any[]>([])
  const [hebrewDates, setHebrewDates] = useState<any[]>([])
  const [reload, setReload] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [savingCalendar, setSavingCalendar] = useState<boolean>(false)
  const [shlCurrentCycle, setShlCurrentCycle] = useState<SHLCycle>()
  const [firstDateInCycle, setFirstDateInCycle] = useState<Date>(new Date())
  const {fetchWithUser} = useAuth()
  const [isOpenModal, setIsOpenModal] = useState<boolean>()
  const [isOpenGenerateCalanderModal, setIsOpenGenerateCalanderModal] =
    useState<boolean>()
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>()
  const {enqueueSnackbar} = useSnackbar()

  const onEventClick = async (event: any) => {
    if (
      event.event._def.extendedProps.calendarEventType &&
      (await isEditable(event))
    ) {
      const eventData = {
        id: event.event.id,
        sourceId: event.event._def.extendedProps.sourceId,
        title: event.event.title,
        shlCycleId: shlCurrentCycle!.shlCycleID,
        dayInUnit: event.event._def.extendedProps.dayInUnit,
        start: event.event.start,
        calendarEventType: event.event._def.extendedProps.calendarEventType,
        allDay: event.event.allDay
      }
      setSelectedEvent(eventData)
      setIsOpenModal(true)
    }
  }

  async function editEvent(eventData: CalendarEvent) {
    if (await isPossibleEditing(eventData)) {
      return fetchWithUser(`${config.apiUrl}/Calendar/UpdateCalendarEvent`, {
        method: 'POST',
        body: JSON.stringify(eventData),
        headers: {'Content-Type': 'application/json'}
      })
        .then(res => res.json())
        .then(res => {
          if (res === 0)
            enqueueSnackbar('impossible target event', {
              variant: 'error'
            })
          else if (res === -1)
            enqueueSnackbar('Target date is not in time the program works', {
              variant: 'error'
            })
          else enqueueSnackbar('The data saved successfully')
        })
        .then(() => {
          const prevEvents = [...currentEvents]
          prevEvents.find(e => e.sourceId === eventData.sourceId).start =
            eventData.start
          setCurrentEvents(prevEvents)
        })
        .then(getCurrentSHLCycleDate)
        .catch(e => {
          enqueueSnackbar('Internal server error, Please try again', {
            variant: 'error'
          })
          throw e
        })
        .finally(() => setSavingCalendar(false))
    } else {
      getCurrentSHLCycleDate()
    }
  }

  const isEditable = async (event: any) => {
    const eventType = event.event._def.extendedProps.calendarEventType
    if (
      !(
        eventType &&
        eventType !== CalendarEventTypeEnum.ExaminationPublish &&
        eventType !== CalendarEventTypeEnum.ExaminationReminder1 &&
        eventType !== CalendarEventTypeEnum.ExaminationReminder2
      )
    ) {
      enqueueSnackbar('Impossible to edit this event', {
        variant: 'error'
      })
      return false
    } else
      return isConfirmedByUser({
        position: 'top',
        text: `Are you sure you want to edit the event '${event.event.title}'`
      })
  }

  const onDrop = async (event: any) => {
    event.allDay = false
    if (await isEditable(event)) {
      const eventData = {
        id: event.event._def.extendedProps.sourceId,
        sourceId: event.event._def.extendedProps.sourceId,
        shlCycleId: shlCurrentCycle!.shlCycleID,
        dayInUnit: event.event._def.extendedProps.dayInUnit,
        title: event.event.title,
        start: getLocalDateFromUtc(event.event.start),
        calendarEventType: event.event._def.extendedProps.calendarEventType
      } as CalendarEvent
      editEvent(eventData)
    } else {
      getCurrentSHLCycleDate()
    }
  }

  const isPossibleEditing = async (editedEvent: CalendarEvent) => {
    switch (editedEvent.calendarEventType) {
      case CalendarEventTypeEnum.dailyUnit:
        if (editedEvent.dayInUnit === 1) {
          if (await validateChapterMoving(editedEvent)) {
            setSavingCalendar(true)
            return true
          }
          return false
        } else return validateEventsOrderOnMoving(editedEvent)
      case CalendarEventTypeEnum.Examination:
        return (
          validateExaminationMoving(editedEvent) &&
          validateIsSunday(editedEvent)
        )
      default:
        return (
          validateEventsOrderOnMoving(editedEvent) &&
          validateIsSunday(editedEvent)
        )
    }
  }

  const getSourceEvent = ({sourceId}: CalendarEvent) => {
    return currentEvents.find(e => e.sourceId === sourceId)
  }

  const isConfirmedByUser = async (props: SweetAlertOptions) => {
    const result = await Swal.fire({
      ...props,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      icon: 'warning',
      confirmButtonColor: '#4caf50'
    })
    return result.isConfirmed
  }

  const validateChapterMoving = async (newStartOfChapter: CalendarEvent) => {
    const sourceDate = getSourceEvent(newStartOfChapter)
    if (newStartOfChapter.start < sourceDate.start)
      if (
        currentEvents.some(
          e =>
            e.calendarEventType === CalendarEventTypeEnum.dailyUnit &&
            e.start >= newStartOfChapter.start &&
            e.start < sourceDate.start
        )
      ) {
        enqueueSnackbar('can`t start a chapter before previous ended', {
          variant: 'error'
        })
        return false
      }
    return isConfirmedByUser({
      title: 'You are editting a whole unit',
      text: 'Are you sure you want to edit the entire continuation of the cycle?'
    })
  }

  const validateExaminationMoving = (newExamEvent: CalendarEvent) => {
    const sourceDate = getSourceEvent(newExamEvent)
    const MiliSecondsIneDay = 24 * 60 * 60 * 1000
    if (
      Math.abs(
        new Date(sourceDate.start).setHours(0, 0, 0, 0) -
          new Date(newExamEvent.start).setHours(0, 0, 0, 0)
      ) /
        MiliSecondsIneDay >
      1
    ) {
      enqueueSnackbar('test comes with the end of a chapter', {
        variant: 'error'
      })
      return false
    }
    return true
  }

  const validateEventsOrderOnMoving = (newCalendarEvent: CalendarEvent) => {
    const sourceDate = getSourceEvent(newCalendarEvent)
    const start =
      sourceDate.start < newCalendarEvent.start
        ? new Date(sourceDate.start).setDate(sourceDate.start.getDate() + 1)
        : newCalendarEvent.start
    const end =
      sourceDate.start > newCalendarEvent.start
        ? new Date(sourceDate.start).setDate(sourceDate.start.getDate() - 1)
        : newCalendarEvent.start
    if (
      currentEvents.some(
        e =>
          e.calendarEventType === newCalendarEvent.calendarEventType &&
          e.start >= start &&
          e.start <= end
      )
    ) {
      enqueueSnackbar('target date mixes up order of events', {
        variant: 'error'
      })
      return false
    }
    return true
  }

  const validateIsSunday = (newCalendarEvent: CalendarEvent) => {
    if (newCalendarEvent.start.getDay() === 0) {
      enqueueSnackbar('Sunday isn`t day of working', {
        variant: 'error'
      })
      return false
    }
    return true
  }

  const getCalendarEventList = useCallback(
    (data: any) => {
      fetchWithUser(`${config.apiUrl}/Calendar/GetCalendarEventsList`)
        .then(response => response.json())
        .then(data => {
          data.forEach((e: CalendarEventExtended) => {
            return (
              (e.start = new Date(getLocalDateFromUtc(new Date(e.start)))),
              (e.end = getLocalDateFromUtc(new Date(e.end))),
              (e.allDay = true),
              (e.backgroundColor = e.calendarEventType
                ? eventBackgroundColor[e.calendarEventType]
                : 'transparent')
            )
          })
          let counter = 1
          const arrayWithIds = data.map((obj: CalendarEventExtended) => {
            return {
              ...obj,
              sourceId: obj.id,
              id: counter++
            }
          })
          setCurrentEvents(arrayWithIds)
        })
        .then(() => {
          if (reload) {
            const start = new Date(data.startDate)
            const end = new Date(data.endDate)
            start.setDate(start.getDate() - 150)
            end.setDate(start.getDate() + 150)
            const startDate = fDateWithYear(start)
            const lastDate = fDateWithYear(end)
            fetch(
              config.hebcalurl +
                `/?cfg=fc&v=1&i=off&maj=on&min=on&nx=on&mf=on&ss=on&mod=on&lg=h&s=off&d=on&start=${startDate}&end=${lastDate}&c=on&geo=none&b=18&m=50&s=off`
            )
              .then(response => response.json())
              .then(data => {
                data = data.map((item: any) => {
                  return {
                    ...item,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    nonEditable: true,
                    isDraggable: false,
                    editable: false
                  }
                })
                setHebrewDates(data)
                setLoading(false)
              })
          }
        })
    },
    [reload, fetchWithUser]
  )

  const getCurrentSHLCycleDate = useCallback(() => {
    fetchWithUser(`${config.apiUrl}/SHLCycle/GetLastSHLCycle`)
      .then(response => response.json())
      .then(data => {
        setShlCurrentCycle({
          ...data,
          endDate: new Date(data.endDate),
          startDate: new Date(data.startDate)
        })
        getCalendarEventList(data)
      })
  }, [getCalendarEventList, fetchWithUser])

  function DisplayHebrewDate(info: any) {
    const hebrowDate = fDateWithYear(info.date)
    const filteredDates = hebrewDates.filter(item => item.start === hebrowDate)
    let element = `${info.dayNumberText}--${filteredDates[0]?.hebrew}`
    return element
  }

  useEffect(() => {
    if (reload) {
      getCurrentSHLCycleDate()
    }
    setReload(false)
  }, [getCurrentSHLCycleDate, reload])

  useEffect(() => {
    if (!!currentEvents.length) {
      const earliestEvent = currentEvents
        ?.map(e => e.start)
        .reduce((earliest, current) =>
          current < earliest ? current : earliest
        )
      setFirstDateInCycle(earliestEvent)
    }
  }, [currentEvents])

  return (
    <>
      {loading ? (
        <CircularProgress
          size={24}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -1.5,
            marginLeft: -1.5
          }}
        />
      ) : (
        <Stack direction={'row'} sx={{width: '100%', height: '100%', mt: 7}}>
          <Stack width={'85%'}>
            {savingCalendar && (
              <Backdrop
                sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
                open={savingCalendar}
              >
                <Typography sx={{whiteSpace: 'pre-wrap'}}>
                  Updating data, Thanks for your patience...{' '}
                </Typography>
                <CircularProgress color="success" />
              </Backdrop>
            )}
            <Card
              sx={{
                borderBlock: 'none',
                maxWidth: '80vw',
                maxHeight: '90vh',
                margin: 'left',
                overflow: 'hidden'
              }}
            >
              <CardContent
                sx={{
                  height: '90vh',
                  width: '95%',
                  margin: 'auto',
                  overflow: 'auto',
                  maxHeight: '100%'
                }}
              >
                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    right: 'today prev,next',
                    center: '',
                    left: 'title'
                  }}
                  dayCellContent={DisplayHebrewDate}
                  titleFormat={{year: 'numeric', month: 'long'}}
                  eventDrop={onDrop}
                  initialView="dayGridMonth"
                  editable={true}
                  dayMaxEvents={true}
                  events={currentEvents}
                  droppable={true}
                  weekends={true}
                  eventContent={RenderEventContent}
                  eventClick={onEventClick}
                />
              </CardContent>
            </Card>{' '}
          </Stack>
          <Stack gap={1}>
            <Button
              variant="contained"
              size="medium"
              sx={{height: 36, ml: 3}}
              disabled={
                !!shlCurrentCycle && shlCurrentCycle.endDate > new Date()
                  ? true
                  : false
              }
              onClick={() => {
                setIsOpenGenerateCalanderModal(true)
              }}
            >
              <CalendarMonthIcon />
              generate Calendar
            </Button>
            {!!shlCurrentCycle && firstDateInCycle > new Date() && (
              <Button
                color="inherit"
                variant="contained"
                size="medium"
                sx={{height: 36, ml: 3}}
                onClick={() => {
                  setIsOpenGenerateCalanderModal(true)
                }}
              >
                <EditCalendarIcon /> edit Calendar
              </Button>
            )}
          </Stack>
          <EventModal
            onCancel={() => setIsOpenModal(false)}
            isOpen={isOpenModal ?? false}
            event={selectedEvent}
            editEvent={editEvent}
          />
          <GeneratetCalanderModal
            isOpen={isOpenGenerateCalanderModal ?? false}
            shlCurrentCycle={shlCurrentCycle}
            getCurrentSHLCycleDate={getCurrentSHLCycleDate}
            closeModal={() => setIsOpenGenerateCalanderModal(false)}
            firstDateInCycle={firstDateInCycle}
          />
        </Stack>
      )}
    </>
  )
}
