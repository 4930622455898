import {ReactNode, useEffect, useState} from 'react'
import Button from '@mui/material/Button'
import Dialog, {DialogProps} from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'
import {ColorSchema} from 'src/theme/palette'
import {IconButton, Stack} from '@mui/material'
import SvgIconStyle from 'src/components/SvgIconStyle'

export interface CuiAlertDialogProps extends DialogProps {
  open: boolean
  close: () => void
  title: string
  body?: ReactNode
  okButtonText: string
  disableBackdropClick?: boolean
  okClick: () => void
  canBeChange?: boolean
  cantBeChangeInfo?: string
  color?: ColorSchema
}

export default function CuiAlertDialog({
  open,
  close,
  title,
  body,
  okButtonText,
  disableBackdropClick,
  okClick,
  canBeChange = true,
  cantBeChangeInfo,
  color,
  ...props
}: CuiAlertDialogProps) {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (open) setLoading(false)
  }, [open])

  const onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!(reason === 'backdropClick' && disableBackdropClick)) {
      close()
    }
  }

  const onOkClick = async () => {
    setLoading(true)
    await okClick()
    setLoading(false)
  }

  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <DialogTitle id="alert-dialog-title">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {title}
          <IconButton onClick={close}>
            <SvgIconStyle src="/assets/icons/ic_close.svg" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {canBeChange ? body : cantBeChangeInfo}
        </DialogContentText>
      </DialogContent>
      {canBeChange && (
        <DialogActions>
          <Button color="inherit" variant="outlined" onClick={close}>
            Cancel
          </Button>
          <CuiProgressButton
            color={color ? color : 'primary'}
            variant="contained"
            onClick={onOkClick}
            loading={loading}
          >
            {okButtonText}
          </CuiProgressButton>
        </DialogActions>
      )}
    </Dialog>
  )
}
