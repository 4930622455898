import {ButtonProps, Box, Button, CircularProgress} from '@mui/material'
import {BoxProps} from '@mui/system'

type CuiProgressButtonProps = ButtonProps & {
  loading?: boolean
  boxProps?: BoxProps
}

export default function CuiProgressButton({
  loading,
  boxProps,
  ...props
}: CuiProgressButtonProps) {
  return (
    <Box sx={{position: 'relative'}} m={1} {...boxProps}>
      <Button disableElevation disabled={loading} {...props} />
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: props.color === 'error' ? 'error.main' : 'primary.main',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -1.5,
            marginLeft: -1.5
          }}
        />
      )}
    </Box>
  )
}
