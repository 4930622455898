import {Stack} from '@mui/material'
import {DataGridPro, useGridApiRef} from '@mui/x-data-grid-pro'
import {
  UserTestEnum,
  userTestColumns,
  DataGridFooterTotalTests
} from 'src/components/shl/participants/participantsUtils'
import CuiToolbar from 'src/components/custom/CuiToolbar'
import {ExaminToEmpType} from 'src/entities/EmployeeToExaminationCycleType'

interface UserTestProps {
  examinationList: ExaminToEmpType[]
  examinationOnTime: number
  examinationLate: number
}

export default function UserTestsPage({
  examinationList,
  examinationOnTime,
  examinationLate
}: UserTestProps) {
  const apiRef = useGridApiRef()

  return (
    <>
      <Stack px={8} spacing={3} height="100%">
        <Stack spacing={3} height="85%" mt="25px">
          <DataGridPro
            apiRef={apiRef}
            rows={examinationList}
            columns={userTestColumns}
            experimentalFeatures={{newEditingApi: true}}
            autoPageSize
            disableExtendRowFullWidth
            headerHeight={42}
            rowHeight={47}
            getRowId={row => row.examinationOrder}
            pageSize={30}
            components={{
              Toolbar: () => (
                <Stack>
                  <CuiToolbar
                    screenId={UserTestEnum.Tests}
                    exportState={apiRef.current.exportState}
                    columnVisibilityModel={
                      apiRef.current.state?.columns.columnVisibilityModel
                    }
                    csvFileName={`Tests`}
                  />
                </Stack>
              ),
              Footer: () => (
                <DataGridFooterTotalTests
                  totalTestSubmited={examinationList.length}
                  onTime={examinationOnTime}
                  late={examinationLate}
                />
              )
            }}
            sx={{
              '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
                background: '#F4F6F8',
                borderRadius: 1
              },
              '.MuiDataGrid-withBorder': {
                borderRight: 'none'
              },
              '.MuiDataGrid-row': {
                cursor: 'pointer'
              },
              '& .isLock': {
                color: 'red'
              },
              '& .loseTicket': {
                color: 'red'
              }
            }}
          />
        </Stack>
      </Stack>
    </>
  )
}
