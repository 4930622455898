import {Language} from 'src/entities/ExaminationType'

export function getEnumOptionWithLabel(type: any) {
  return Object.keys(type).reduce((prev, dpType) => {
    const resNumber = Number(dpType)
    if (!isNaN(resNumber) && resNumber) {
      prev.push({
        value: resNumber as any,
        label: type[resNumber]
      })
    }
    return prev
  }, [] as {value: any; label: string}[])
}

export function getEnumOptionWithKey(type: any) {
  return Object.keys(type)
    .filter(key => isNaN(Number(type[key as any])))
    .map(val => ({
      key: +val,
      value: type[+val]
    }))
}

export const LanguagesList = getEnumOptionWithLabel(Language)
