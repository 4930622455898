// components
import {SettingsValueProps} from './components/settings/type'

// LAYOUT
// ----------------------------------------------------------------------

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32
}
export const HEADER = {
  MOBILE_HEIGHT: 48,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 48,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32
}

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20
}

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'blue',
  themeStretch: false
}
const config = {
  AADRedirectUrl: process.env.REACT_APP_AAD_REDIRECT_URL || '',
  AADConfig: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID || '',
    authority: process.env.REACT_APP_AAD_AUTHORITY || ''
  },
  apiUrl: process.env.REACT_APP_API || '',
  isProduction: process.env.REACT_APP_BRANCH_NAME === 'main',
  isMain: process.env.REACT_APP_BRANCH_NAME === 'main',
  isDevelopment: process.env.NODE_ENV === 'development',
  logRocketAppId: process.env.REACT_APP_LOGROCKET_APPID || '',
  rollbarPostClientItem: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM || '',
  MUIDataGridLicenseKey: process.env.REACT_APP_MUI_DATA_GRID_LICENSE_KEY || '',
  hebcalurl: `https://www.hebcal.com/hebcal`
}

export default config
