import React from 'react'
import {Box} from '@mui/material'

interface DataGridTotalRowsProps {
  amount: number
}

export default function DataGridTotalRows({amount}: DataGridTotalRowsProps) {
  return (
    <Box sx={{textAlign: 'end', my: 1.5, mr: 2}}>
      <span> Total rows {amount}</span>
    </Box>
  )
}
