import ExaminationType from 'src/entities/ExaminationType'
export default interface EmployeeToSHL {
  employeeToSHLCycleID: number
  employeeID: number
  firstName: string
  lastName: string
  mail?: string
  phone?: string
  departmentName?: string
  officeName?: string
  city?: string
  examinationOrderStart?: number
  isActive: boolean
  isNew: boolean
  isML?: boolean
  examinationTypeID: number
  inviteDate?: Date
  joinDate?: Date
  exitDate?: Date
  needSeferID: NeedSeferEnum
  color?: string
  examinationTypeShortName?: string
  totalTestsDone?: number
  examinationType?: ExaminationType
  mLStartDate?: Date
  returnDate?: Date
}

export enum NeedSeferEnum {
  NoNeed = 1,
  Need,
  Got,
  'No need a sefer' = NoNeed,
  'Waiting for a sefer' = Need,
  'Got a sefer' = Got
}
