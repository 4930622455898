import LotteryEventType from 'src/entities/LotteryEventType'
import RaffleEventType from 'src/entities/RaffleEventType'
import {getLocalDateFromString} from 'src/utils/formatTime'

export enum GridColumnRaffleEvent {
  RaffleEvent = 'raffleEvent',
  RaffleDescription = 'raffleDescription',
  EventDate = 'eventDate',
  RaffleEdit = 'raffleEdit',
  RaffleDelete = 'raffleDelete',
  RafflWinners = 'lotteriesAmount',
  RaffleLink = 'raffleLink'
}

export enum GridColumnRaffleEventParticipants {
  ParticipantId = 'participantId',
  ParticipantName = 'participantName',
  ParticipantMail = 'participantMail',
  ParticipantCity = 'participantCity',
  ParticipantOffice = 'participantOffice',
  ParticipantLotteryTickets = 'ticketsNumber'
}

export type LotteryEventTypeFromApi = LotteryEventType & {
  event: RaffleEventType & {
    eventDate?: string
    updatedDate?: string
  }
}

export const convertFromApiToLotteryEventType = (
  raffle: LotteryEventTypeFromApi
): LotteryEventType => ({
  ...raffle,
  event: {
    ...raffle.event,
    eventDate: getLocalDateFromString(raffle.event.eventDate),
    updatedDate: getLocalDateFromString(raffle.event.updatedDate)
  }
})

export enum RaffleScreenEnum {
  Raffles = 7
}
