import React from 'react'
import { Stack } from '@mui/material'
import CuiToolbar, { CuiToolbarProps } from 'src/components/custom/CuiToolbar'
import FilterToolbar, {
  TrackFilterToolbarProps
} from 'src/components/shl/shared/FilterToolbar'

interface TestExamineeToolbarProps {
  cuiToolbarProps: CuiToolbarProps
  trackFilterToolbarProps: TrackFilterToolbarProps
}

export default function TestExamineeToolbar({
  cuiToolbarProps,
  trackFilterToolbarProps
}: TestExamineeToolbarProps) {
  return (
    <Stack>
      <FilterToolbar {...trackFilterToolbarProps} />
      <CuiToolbar {...cuiToolbarProps} />
    </Stack>
  )
}
