import React, {FormEvent, useCallback, useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import randomColor from 'randomcolor'
import {GridRowModelUpdate} from '@mui/x-data-grid-pro'
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Avatar,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField
} from '@mui/material'
import {EmployeeToExaminationCycleType} from 'src/entities/EmployeeToExaminationCycleType'
import {EmployeeWithColor} from 'src/entities/EmployeeWithColor'
import {useAuth} from 'src/contexts/Auth'
import {getBase64} from 'src/utils/fileHelper'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import SvgIconStyle from 'src/components/SvgIconStyle'
import config from 'src/config'
import UploadSingleFile from 'src/components/upload/UploadSingleFile'
import Employee from 'src/entities/Employee'
import CuiDialogTitle from 'src/components/custom/CuiDialogTitle'
import ExaminationCycleType from 'src/entities/ExaminationCycleType'

enum TestExamineeFormType {
  UploadFile = 'Upload File',
  FillExaminee = 'Fill Examinee'
}
interface TestExamineePageProps {
  onCancelClick: () => void
  isOpen: boolean
  testId: number
  testOrderNumber?: number
  getExamineesList: () => void
  getExamineeById: (empId: number) => EmployeeToExaminationCycleType | null
  setExamineeList: (updates: GridRowModelUpdate[]) => void
  testTrakMissQNList?: ExaminationCycleType[]
}

export default function TestExamineeModal({
  onCancelClick,
  isOpen,
  testId,
  testOrderNumber,
  getExamineesList,
  getExamineeById,
  setExamineeList,
  testTrakMissQNList
}: TestExamineePageProps) {
  const [examineeForm, setExamineeForm] =
    useState<EmployeeToExaminationCycleType>({
      submissionTime: new Date()
    } as EmployeeToExaminationCycleType)
  const [employeeList, setEmployeeList] = useState<EmployeeWithColor[]>()
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState<boolean>()
  const [testExamineeFormType, setTestExamineeFormType] = useState<string>(
    TestExamineeFormType.UploadFile
  )
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()

  const getEmployeeList = useCallback(() => {
    fetchWithUser(
      config.apiUrl + `/Examination/GetEmpNotExaminedListByExamCycId/${testId}`
    )
      .then(res => res.json())
      .then((data: Employee[]) => {
        const rows: EmployeeWithColor[] = data.map(et => ({
          key: et.employeeID,
          value: `${et.employeeID} ${et.lastName} ${et.firstName}`,
          color: randomColor({luminosity: 'bright'})
        }))
        setEmployeeList(rows)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again', {
          variant: 'error'
        })
        throw e
      })
  }, [fetchWithUser, enqueueSnackbar, testId])

  useEffect(getEmployeeList, [getEmployeeList])

  const dropSingleFile = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    if (file) {
      setFile(
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    }
  }, [])

  const saveFile = () => {
    if (!file) return
    getBase64(file).then((e: string) => {
      fetchWithUser(
        config.apiUrl + `/Examination/AddEmpToExamCycListFromExcel/${testId}`,
        {
          method: 'POST',
          body: JSON.stringify(e),
          headers: {
            'Content-Type': 'application/json',
            accept: 'text/plain'
          }
        }
      )
        .then(res => res.json())
        .then((data: string[]) => {
          if (data.length === 0) enqueueSnackbar('The data saved success')
          else
            enqueueSnackbar(
              <>
                The data saved success except the records:
                <br />
                {data.map((row, i) => (
                  <>
                    {i + 1}. {row}
                    <br />
                  </>
                ))}
              </>,
              {
                variant: 'warning',
                persist: true
              }
            )
          setLoading(false)
          getEmployeeList()
          getExamineesList()
        })
        .catch(e => {
          enqueueSnackbar('Internal server error, Please try again', {
            variant: 'error'
          })
          setLoading(false)
          throw e
        })
    })
  }

  const saveExaminee = () => {
    fetchWithUser(
      config.apiUrl + `/Examination/AddEmpToExamCycTypeByExamCycId/${testId}`,
      {
        method: 'POST',
        body: JSON.stringify(examineeForm),
        headers: {
          'Content-Type': 'application/json',
          accept: 'text/plain'
        }
      }
    )
      .then(res => res.json())
      .then(
        (data: EmployeeToExaminationCycleType & {submissionTime?: string}) => {
          setEmployeeList(prev =>
            prev?.filter(e => e.key !== examineeForm.employeeID)
          )
          setExamineeList([
            {
              ...data,
              examination: getExamineeById(data.employeeID),
              submissionTime: data.submissionTime
            }
          ])
          setExamineeForm(prev => ({
            ...prev,
            employeeID: -1,
            score: -1,
            submissionTime: undefined
          }))
          enqueueSnackbar('The data saved success')
          setLoading(false)
        }
      )
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again', {
          variant: 'error'
        })
        setLoading(false)
        throw e
      })
  }

  const submitForm = async (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault()
    setLoading(true)
    if (testExamineeFormType === TestExamineeFormType.UploadFile) {
      await saveFile()
    } else {
      await saveExaminee()
    }
  }

  return (
    <Dialog open={isOpen} fullWidth>
      <CuiDialogTitle
        title={`Add Examinees To Test ${testOrderNumber ?? ''}`}
        onCancelClick={onCancelClick}
      />
      <DialogContent>
        {!testTrakMissQNList ? (
          <Stack p={8}>
            <CircularProgress
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%'
              }}
            />
          </Stack>
        ) : testTrakMissQNList.length === 0 ? (
          <Stack mt={1}>
            <form onSubmit={submitForm}>
              <Grid container height={200} rowSpacing={3} columnSpacing={3}>
                <Grid item xs={12}>
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={testExamineeFormType}
                    onChange={(e, value) => setTestExamineeFormType(value)}
                  >
                    <FormControlLabel
                      value={TestExamineeFormType.UploadFile}
                      control={<Radio />}
                      label={TestExamineeFormType.UploadFile}
                    />
                    <FormControlLabel
                      value={TestExamineeFormType.FillExaminee}
                      control={<Radio />}
                      label={TestExamineeFormType.FillExaminee}
                    />
                  </RadioGroup>
                </Grid>
                {testExamineeFormType === TestExamineeFormType.UploadFile ? (
                  <Grid item xs={12}>
                    <UploadSingleFile
                      setFile={setFile}
                      onDrop={dropSingleFile}
                      file={file}
                      loading={false}
                      onDelete={() => {}}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        limitTags={1}
                        options={employeeList || []}
                        loading={!employeeList}
                        renderOption={(props, e: EmployeeWithColor) => (
                          <MenuItem
                            {...props}
                            key={e.key}
                            value={`${e.key} ${e.value}`}
                            sx={{display: 'flex', pr: 0}}
                          >
                            <Avatar
                              sx={{
                                fontSize: '0.875rem',
                                mr: 2,
                                backgroundColor: e.color,
                                color: '#fff'
                              }}
                            >
                              {e.value.split(' ')[1].charAt(0)}
                              {e.value.split(' ')[2].charAt(0)}
                            </Avatar>
                            <ListItemText primary={e.value} />
                            {examineeForm.employeeID === e.key && (
                              <ListItemSecondaryAction sx={{right: 0}}>
                                <IconButton sx={{width: 40}}>
                                  <SvgIconStyle
                                    src="/assets/icons/ic_v.svg"
                                    sx={{width: 16, bgcolor: 'primary.main'}}
                                  />
                                </IconButton>
                              </ListItemSecondaryAction>
                            )}
                          </MenuItem>
                        )}
                        getOptionLabel={e => e.value}
                        onChange={(e, value: EmployeeWithColor | null) =>
                          setExamineeForm(prev => ({
                            ...prev,
                            employeeID: value?.key ?? 0
                          }))
                        }
                        value={
                          employeeList?.find(
                            e => e.key === examineeForm.employeeID
                          ) ?? null
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Employee"
                            placeholder="Employee"
                            required
                          />
                        )}
                        sx={{
                          '.MuiAutocomplete-tag': {
                            maxWidth: 'calc(100% - 71px)'
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="score"
                        label="Score"
                        type="number"
                        fullWidth
                        value={
                          examineeForm.score && examineeForm.score > -1
                            ? examineeForm.score
                            : ''
                        }
                        InputProps={{inputProps: {min: 0, max: 100}}}
                        onChange={({target}) =>
                          setExamineeForm(prev => ({
                            ...prev,
                            score: +target.value
                          }))
                        }
                        required
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <DialogActions
                sx={{padding: '24px 0 0 0!important', marginTop: 5}}
              >
                <CuiCancelSaveButton
                  onCancel={onCancelClick}
                  loading={loading}
                  typeSaveButton="submit"
                  disableSave={
                    loading ||
                    (testExamineeFormType ===
                      TestExamineeFormType.FillExaminee &&
                      examineeForm.submissionTime &&
                      isNaN(examineeForm.submissionTime.getTime())) ||
                    (testExamineeFormType === TestExamineeFormType.UploadFile &&
                      !file)
                  }
                  disableCancel={loading}
                />
              </DialogActions>
            </form>
          </Stack>
        ) : (
          <Alert severity="error">
            <AlertTitle>Oppsss</AlertTitle>
            Please insert total points before uploading the excel for{' '}
            {testTrakMissQNList.length === 10 ? (
              <strong>each tracks</strong>
            ) : (
              <>
                tracks:{' '}
                <strong>
                  {testTrakMissQNList
                    .map(
                      e =>
                        e.examination.examinationType?.examinationTypeShortName
                    )
                    .join(', ')}
                </strong>
              </>
            )}{' '}
            hasn’t been entered yet.
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  )
}
