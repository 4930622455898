import {useCallback, useEffect, useRef, useState} from 'react'
import {useSnackbar} from 'notistack'
import {
  GridColumnVisibilityModel,
  GridExportStateParams
} from '@mui/x-data-grid-pro'
import {GridInitialStatePro} from '@mui/x-data-grid-pro/models/gridStatePro'
import {
  getFieldToSort,
  initialColumnVisibilityDict,
  ParticipantsTypeEnum
} from 'src/components/shl/participants/participantsUtils'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'

export default function useInitalState(
  screenId: number,
  exportState: (
    params?: GridExportStateParams
  ) => GridInitialStatePro | undefined,
  restoreState: (stateToRestore: GridInitialStatePro) => void,
  setColumnVisibilityModel: (model: GridColumnVisibilityModel) => void
) {
  const [initialState, setInitialState] = useState<GridInitialStatePro>()
  const defaultState = useRef<GridInitialStatePro | undefined>()
  const {employee, fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()

  const getInitalState = useCallback(() => {
    if (!employee || !exportState) return
    fetchWithUser(
      config.apiUrl +
        `/Employee/GetEmployeeToScreenState?employeeId=${employee.employeeID}&screenId=${screenId}`
    )
      .then(res => res.text())
      .then(data => data && JSON.parse(data))
      .then(data => {
        if (!defaultState.current) defaultState.current = exportState()
        setInitialState(
          data && data !== ''
            ? JSON.parse(data.state)
            : {
                ...defaultState.current,
                columns: {
                  ...defaultState.current?.columns,
                  columnVisibilityModel:
                    screenId in ParticipantsTypeEnum
                      ? initialColumnVisibilityDict[
                          screenId as ParticipantsTypeEnum
                        ]
                      : {}
                },
                sorting: {
                  sortModel: [
                    screenId in ParticipantsTypeEnum
                      ? {field: getFieldToSort(screenId), sort: 'desc'}
                      : {}
                  ]
                }
              }
        )
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }, [fetchWithUser, enqueueSnackbar, exportState, employee, screenId])

  const loadState = useCallback(() => {
    if (initialState) {
      restoreState(initialState)
      if (initialState.columns?.columnVisibilityModel)
        setColumnVisibilityModel(initialState.columns.columnVisibilityModel)
    }
  }, [restoreState, setColumnVisibilityModel, initialState])

  useEffect(getInitalState, [getInitalState])
  useEffect(loadState, [loadState])

  return {initialState}
}
