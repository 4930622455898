import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid-pro'
import {Stack, Tooltip, FormControlLabel, Switch} from '@mui/material'
import AlarmOnIcon from '@mui/icons-material/AlarmOn'
import AlarmOffIcon from '@mui/icons-material/AlarmOff'
import Filter3Icon from '@mui/icons-material/Filter3'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import {globalSettingsColumnWithSort} from 'src/settings/type'
import {fDateTimeSuffix, getLocalDateFromString} from 'src/utils/formatTime'
import PeopleAmountBox from 'src/components/shl/shared/PeopleAmountBox'
import {ExaminationCycle} from 'src/entities/ExaminationCycle'
import {getEnumOptionWithKey} from 'src/utils/enumHelper'
import {SubmissionStatusEnum} from 'src/entities/EmployeeToExaminationCycleType'
import BlockIcon from '@mui/icons-material/Block'
import EditIconButton from 'src/components/shl/shared/EditIconButton'
import BabyIcon from 'src/images/babyIcon.svg'

export type ExaminationCycleFromApi = ExaminationCycle & {
  publishedTime?: string
  submissionTime?: string
  reminder1?: string
  reminder2?: string
}

export enum GridColumnTests {
  ExaminationOrder = 'examinationOrder',
  ExaminationName = 'examinationName',
  PublishedTime = 'publishedTime',
  SubmissionTime = 'submissionTime',
  Reminder1 = 'reminder1',
  Reminder2 = 'reminder2',
  TotalExaminees = 'totalExaminees',
  IsLock = 'isLock',
  Edit = 'edit'
}

export enum GridColumnTestTracks {
  Track = 'track',
  TotalQuestions = 'totalQuestions',
  ExaminationUrl = 'examinationUrl',
  TotalExamineesOnTime = 'totalExamineesOnTime',
  TotalExamineesLate = 'totalExamineesLate',
  TotalNotExaminees = 'totalNotExaminees',
  AvgGrade = 'avgGrade',
  TotalLotteryTickets = 'totalLotteryTickets',
  Actions = 'actions'
}

export enum GridColumnExaminess {
  EmployeeID = 'employeeID',
  Name = 'name',
  Track = 'track',
  SubmissionTime = 'submissionTime',
  SubmissionStatus = 'submissionStatus',
  LotteryTicketsNumber = 'lotteryTicketsNumber',
  Score = 'score'
}

export default function testColumns(
  onLockTest: (test: ExaminationCycle) => Promise<void>,
  onEditTest: (test: ExaminationCycle) => Promise<void>
) {
  const testColumns: GridColDef[] = [
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTests.ExaminationOrder,
      headerName: 'Name',
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) => `Test ${params.value}`
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTests.TotalExaminees,
      headerName: 'Total Examinees',
      type: 'number',
      flex: 0.14,
      renderCell: (params: GridRenderCellParams) => (
        <PeopleAmountBox amount={params.value} />
      )
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTests.PublishedTime,
      headerName: 'Publish Time',
      type: 'date',
      flex: 0.19,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && fDateTimeSuffix(params.value)
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTests.Reminder1,
      headerName: 'Reminder 1',
      type: 'date',
      flex: 0.19,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && fDateTimeSuffix(params.value)
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTests.Reminder2,
      headerName: 'Reminder 2',
      type: 'date',
      flex: 0.19,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && fDateTimeSuffix(params.value)
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTests.SubmissionTime,
      headerName: 'Submission Time',
      type: 'date',
      flex: 0.19,
      valueFormatter: (params: GridValueFormatterParams) =>
        params.value && fDateTimeSuffix(params.value)
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTests.IsLock,
      headerName: 'IsLock',
      type: 'boolean',
      renderCell: (params: GridRenderCellParams) => (
        <FormControlLabel
          sx={{m: 0}}
          checked={params.value}
          onChange={() => onLockTest(params.row)}
          control={<Switch />}
          label=""
        />
      )
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnTests.Edit,
      headerName: 'Edit',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => (
        <EditIconButton onClick={() => onEditTest(params.row)} />
      )
    }
  ]
  return testColumns
}

export const examineeColumns: GridColDef[] = [
  {
    ...globalSettingsColumnWithSort,
    field: GridColumnExaminess.EmployeeID,
    headerName: 'ID',
    flex: 0.07
  },
  {
    ...globalSettingsColumnWithSort,
    field: GridColumnExaminess.Name,
    headerName: 'Name',
    flex: 0.27,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.employee.lastName} ${params.row.employee.firstName} `,
    renderCell: (params: GridRenderCellParams) => (
      <>
        {params.row.employee.lastName} {params.row.employee.firstName}
        {params.row.isML && (
          <img
            style={{
              height: 20,
              width: 20,
              marginLeft: 4
            }}
            src={BabyIcon}
            alt=""
          />
        )}
      </>
    )
  },
  {
    ...globalSettingsColumnWithSort,
    field: GridColumnExaminess.Track,
    headerName: 'Track',
    flex: 0.25,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.examinationCycleType.examination.examinationType
        ?.examinationTypeShortName
  },
  {
    ...globalSettingsColumnWithSort,
    editable: true,
    field: GridColumnExaminess.Score,
    headerName: 'Score',
    type: 'number',
    flex: 0.2,
    renderCell: (params: GridRenderCellParams) => (
      <Stack
        width="70px"
        direction="row"
        alignItems="center"
        justifyContent="center"
        ml={params.row.lotteryTicketsNumber !== 2 ? 4 : 0}
      >
        {params.row.lotteryTicketsNumber === 2 && (
          <Tooltip
            children={<Filter3Icon sx={{color: 'primary.main', mr: 1}} />}
            title={'Got 3 100s'}
          />
        )}
        {params.row.submissionStatus !== SubmissionStatusEnum.Lack && (
          <Stack m="auto">
            {params.row.score} /{' '}
            {params.row.examinationCycleType.totalQuestions}
          </Stack>
        )}
      </Stack>
    )
  },
  {
    ...globalSettingsColumnWithSort,
    field: GridColumnExaminess.LotteryTicketsNumber,
    headerName: 'Raffle Tickets',
    type: 'number',
    flex: 0.2,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip
        children={
          <Stack>
            {params.row.submissionStatus !== SubmissionStatusEnum.Lack && (
              <PeopleAmountBox
                amount={
                  <>
                    {params.row.isWinnersLottery && `(`}
                    <HowToVoteIcon
                      sx={{
                        ml: !params.row.isWinnersLottery ? 0.45 : 0,
                        color: 'primary.main'
                      }}
                    />
                    {params.value || 0}
                    {params.row.isWinnersLottery && `)`}
                  </>
                }
                color={'primary.main'}
                icon={null}
              />
            )}
          </Stack>
        }
        title={params.row.isWinnersLottery ? 'Winners lottery' : ''}
      />
    )
  },
  {
    ...globalSettingsColumnWithSort,
    field: GridColumnExaminess.SubmissionStatus,
    headerName: 'Status',
    type: 'singleSelect',
    flex: 0.25,
    valueOptions: getEnumOptionWithKey(SubmissionStatusEnum).map(
      ({key, value}) => ({
        label: value,
        value: key
      })
    ),
    renderCell: (params: GridRenderCellParams) => {
      switch (params.value) {
        case 1:
          return <AlarmOnIcon sx={{color: 'primary.main', mr: 3}} />
        case 2:
          return <AlarmOffIcon sx={{color: 'red', mr: 3}} />
        default:
          return <BlockIcon sx={{color: 'yellow', mr: 3}} />
      }
    }
  },
  {
    ...globalSettingsColumnWithSort,
    field: GridColumnExaminess.SubmissionTime,
    headerName: 'Submission Time',
    type: 'date',
    flex: 0.25,
    valueGetter: (params: GridValueFormatterParams) =>
      params.value && fDateTimeSuffix(params.value)
  }
]

export const convertFromApiToExaminationCycle = (
  examCycle: ExaminationCycleFromApi
): ExaminationCycle => ({
  ...examCycle,
  publishedTime: getLocalDateFromString(examCycle.publishedTime),
  submissionTime: getLocalDateFromString(examCycle.submissionTime),
  reminder1: getLocalDateFromString(examCycle.reminder1),
  reminder2: getLocalDateFromString(examCycle.reminder2)
})

export enum TestScreenEnum {
  ExaminationCycles = 4,
  ExaminationCycleTypes,
  Examinees
}
