import React from 'react'
import {useSnackbar} from 'notistack'
import {Button, Stack, Theme} from '@mui/material'
import {
  GridColumnVisibilityModel,
  GridExportStateParams,
  GridToolbar
} from '@mui/x-data-grid-pro'
import SaveIcon from '@mui/icons-material/Save'
import {GridInitialStatePro} from '@mui/x-data-grid-pro/models/gridStatePro'
import EmployeeToScreenState from 'src/entities/EmployeeToScreenState'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'

export interface CuiToolbarProps {
  screenId: number
  columnVisibilityModel?: GridColumnVisibilityModel
  exportState: (params?: GridExportStateParams) => GridInitialStatePro
  csvFileName?: string
}

export default function CuiToolbar({
  screenId,
  columnVisibilityModel,
  exportState,
  csvFileName
}: CuiToolbarProps) {
  const {fetchWithUser, employee} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const saveToDefault = () => {
    if (!employee) return
    const stateToSave: GridInitialStatePro = exportState()
    stateToSave.filter = undefined
    if (stateToSave.columns) {
      stateToSave.columns.columnVisibilityModel = columnVisibilityModel
    }
    const employeeToScreenState: EmployeeToScreenState = {
      employeeID: employee.employeeID,
      screenID: screenId,
      state: JSON.stringify(stateToSave)
    }
    fetchWithUser(config.apiUrl + '/Employee/AddEmployeeToScreenState', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(employeeToScreenState)
    })
      .then(() => {
        enqueueSnackbar('The state is saved as default')
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }

  return (
    <Stack direction="row" spacing={2}>
      <GridToolbar
        sx={{
          backgroundColor: (theme: Theme) => theme.palette.background.paper
        }}
        csvOptions={{
          fileName: csvFileName,
          utf8WithBom: true
        }}
        printOptions={{disableToolbarButton: true}}
      />
      <Button
        variant="text"
        size="medium"
        sx={{
          height: 36,
          color: '#212B36',
          fontWeight: 700,
          fontSize: '0.8125rem',
          ml: '0!important',
          pt: 1.25,
          pl: 0
        }}
        onClick={saveToDefault}
      >
        <SaveIcon sx={{mr: 1, fontSize: 18}} />
        Save To Default
      </Button>
    </Stack>
  )
}
