import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, {SelectProps} from '@mui/material/Select'

export interface SelectOption {
  value: number | string
  label: string
}

interface CuiSelectWithOptionsProps extends SelectProps {
  options: SelectOption[]
  withAllOption?: boolean
  fullWidth?: boolean
}

export default function CuiSelectWithOptions({
  options,
  withAllOption,
  fullWidth,
  ...props
}: CuiSelectWithOptionsProps) {
  return (
    <FormControl sx={fullWidth ? {} : {minWidth: 200, width: 200}}>
      <InputLabel>{props.label}</InputLabel>
      <Select {...props}>
        {withAllOption && <MenuItem value={0}>All</MenuItem>}
        {options.map(o => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
