import React from 'react'
import {
  Box,
  FormControl,
  Icon,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tooltip
} from '@mui/material'
import SvgIconStyle from 'src/components/SvgIconStyle'
import {NeedSeferEnum} from 'src/entities/EmployeeToSHL'
import {getEnumOptionWithKey} from 'src/utils/enumHelper'

interface NeedSeferIconProps {
  status: NeedSeferEnum
  withTooltip?: boolean
}

export function NeedSeferIcon({
  status,
  withTooltip = false
}: NeedSeferIconProps) {
  const getIconProps = () => {
    switch (status) {
      case NeedSeferEnum.Need:
        return {
          src: '/assets/icons/ic_check.svg',
          sx: {bgcolor: 'red'}
        }
      case NeedSeferEnum.Got:
        return {
          src: '/assets/icons/ic_check.svg',
          sx: {bgcolor: 'rgba(0, 171, 85)'}
        }
      default:
        return {
          src: '/assets/icons/ic_remove.svg',
          sx: {bgcolor: '#C4CDD5'}
        }
    }
  }

  return (
    <Tooltip
      title={
        <Box textAlign="center">
          {getEnumOptionWithKey(NeedSeferEnum)[status - 1].value}
          <br />
          Click to edit
        </Box>
      }
      disableHoverListener={!withTooltip}
    >
      <Icon sx={{display: 'flex'}}>
        <SvgIconStyle {...getIconProps()} />
      </Icon>
    </Tooltip>
  )
}

interface NeedSeferSelectProps {
  value: NeedSeferEnum
  onChange: (event: SelectChangeEvent) => void
}

export function NeedSeferSelect({value, onChange}: NeedSeferSelectProps) {
  return (
    <FormControl fullWidth>
      <InputLabel id="need-sefer-select-label">Need Sefer</InputLabel>
      <Select
        required
        labelId="need-sefer-label"
        id="need-sefer-select"
        value={value.toString()}
        label="Need Sefer"
        onChange={onChange}
        input={<OutlinedInput label="Need sefer" />}
        renderValue={val => <NeedSeferIcon status={+val} />}
        sx={{
          '#need-sefer-select': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: 1,
            pl: 4,
            minHeight: 40
          }
        }}
      >
        {getEnumOptionWithKey(NeedSeferEnum).map(({key, value}) => (
          <MenuItem key={key} value={key} sx={{display: 'flex'}}>
            <ListItemAvatar sx={{display: 'flex'}}>
              <NeedSeferIcon status={key} />
            </ListItemAvatar>
            <ListItemText primary={value} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
