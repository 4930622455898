import React, {Dispatch, FormEvent, SetStateAction, useState} from 'react'
import {
  Autocomplete,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material'
import {useSnackbar} from 'notistack'
import SvgIconStyle from 'src/components/SvgIconStyle'
import ExaminationType from 'src/entities/ExaminationType'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import {EmployeeWithColor} from 'src/entities/EmployeeWithColor'
import CuiDialogTitle from 'src/components/custom/CuiDialogTitle'

export enum StatusModal {
  Close,
  Create,
  Update
}
interface TrackModalProps {
  onCancelClick: () => void
  onSaveClick: (updates: ExaminationType) => void
  examintionTypeForm: ExaminationType
  setExamintionTypeForm: Dispatch<SetStateAction<ExaminationType>>
  statusModal: StatusModal
  reviewerList?: EmployeeWithColor[]
}

export default function TrackModal({
  onCancelClick,
  onSaveClick,
  examintionTypeForm,
  setExamintionTypeForm,
  statusModal,
  reviewerList
}: TrackModalProps) {
  const [loading, setLoading] = useState<boolean>()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()

  const submitForm = (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault()
    setLoading(true)
    fetchWithUser(
      config.apiUrl +
        `/Examination/${
          statusModal === StatusModal.Create ? 'Add' : 'Update'
        }ExaminationType`,
      {
        method: 'POST',
        body: JSON.stringify({
          ...examintionTypeForm,
          reviewers: examintionTypeForm.isOpenQuestions
            ? examintionTypeForm.reviewers
            : []
        }),
        headers: {'Content-Type': 'application/json'}
      }
    )
      .then(res => res.json())
      .then((data: number) => {
        setLoading(false)
        enqueueSnackbar('Your changes have been successfully saved')
        onSaveClick({
          ...examintionTypeForm,
          examinationTypeID:
            statusModal === StatusModal.Create
              ? data
              : examintionTypeForm.examinationTypeID
        })
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        setLoading(false)
        throw e
      })
  }

  return (
    <Dialog open={statusModal !== StatusModal.Close} fullWidth>
      <CuiDialogTitle
        title={
          statusModal === StatusModal.Create
            ? 'Create a new track'
            : 'Update track'
        }
        onCancelClick={onCancelClick}
      />
      <DialogContent>
        <Stack spacing={3} mt={1}>
          <form onSubmit={form => submitForm(form)}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={6}>
                <TextField
                  name="trackName"
                  label="Name"
                  fullWidth
                  value={examintionTypeForm.examinationTypeName}
                  onChange={({target}) =>
                    setExamintionTypeForm(prev => ({
                      ...prev,
                      examinationTypeName: target.value
                    }))
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="trackShortName"
                  label="Short name"
                  fullWidth
                  value={examintionTypeForm.examinationTypeShortName}
                  onChange={({target}) =>
                    setExamintionTypeForm(prev => ({
                      ...prev,
                      examinationTypeShortName: target.value
                    }))
                  }
                  required
                />
              </Grid>
              <Grid item xs={12}>
                Test details
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="language-select-label">Language</InputLabel>
                  <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={examintionTypeForm.languageID ?? ''}
                    label="Language"
                    onChange={({target}) =>
                      setExamintionTypeForm(prev => ({
                        ...prev,
                        languageID: +target.value
                      }))
                    }
                    required
                  >
                    <MenuItem disabled value="" />
                    <MenuItem value={1}>English</MenuItem>
                    <MenuItem value={2}>Hebrew</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="book-select-label">
                    Book Open/Closed
                  </InputLabel>
                  <Select
                    labelId="book-select-label"
                    id="book-select"
                    value={examintionTypeForm.isOpenBook ?? ''}
                    label="Book"
                    onChange={({target}) =>
                      setExamintionTypeForm(prev => ({
                        ...prev,
                        isOpenBook: target.value === 'true'
                      }))
                    }
                    required
                  >
                    <MenuItem disabled value="" />
                    <MenuItem value={'true'}>Open</MenuItem>
                    <MenuItem value={'false'}>Close</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="questions-select-label">
                    Questions type
                  </InputLabel>
                  <Select
                    labelId="questions-select-label"
                    id="questions-select"
                    value={examintionTypeForm.isOpenQuestions ?? ''}
                    label="Questions"
                    onChange={({target}) =>
                      setExamintionTypeForm(prev => ({
                        ...prev,
                        isOpenQuestions: target.value === 'true'
                      }))
                    }
                    required
                  >
                    <MenuItem disabled value="" />
                    <MenuItem value={'true'}>Free text</MenuItem>
                    <MenuItem value={'false'}>Multiple choice</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {examintionTypeForm.isOpenQuestions && (
                <Grid item xs={6}>
                  <Autocomplete
                    multiple
                    fullWidth
                    limitTags={1}
                    options={reviewerList || []}
                    renderOption={(props, r: EmployeeWithColor) => (
                      <MenuItem
                        {...props}
                        key={r.key}
                        value={r.key}
                        sx={{display: 'flex', pr: 0}}
                      >
                        <Avatar
                          sx={{
                            fontSize: '0.875rem',
                            mr: 2,
                            backgroundColor: r.color,
                            color: '#fff'
                          }}
                        >
                          {r.value.charAt(0)}
                          {r.value.split(' ')[1].charAt(0)}
                        </Avatar>
                        <ListItemText primary={r.value} />
                        {examintionTypeForm.reviewers.includes(r.key) && (
                          <ListItemSecondaryAction sx={{right: 0}}>
                            <IconButton sx={{width: 40}}>
                              <SvgIconStyle
                                src="/assets/icons/ic_v.svg"
                                sx={{width: 16, bgcolor: 'primary.main'}}
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        )}
                      </MenuItem>
                    )}
                    getOptionLabel={r => r.value}
                    onChange={(e, newValue: EmployeeWithColor[]) => {
                      setExamintionTypeForm(prev => {
                        const newReviewers = newValue.map(e => e.key)
                        return {
                          ...prev,
                          reviewers: newReviewers,
                          reviewerNames: reviewerList
                            ?.filter(r => newReviewers?.includes(r.key))
                            .map(r => r.value)
                        }
                      })
                    }}
                    value={
                      reviewerList && examintionTypeForm.reviewers
                        ? examintionTypeForm.reviewers.map(
                            r => reviewerList.find(opt => opt.key === r)!
                          )
                        : []
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Reviewers"
                        placeholder="Reviewers"
                        required={!examintionTypeForm.reviewers.length}
                      />
                    )}
                    sx={{
                      '.MuiAutocomplete-tag': {
                        maxWidth: 'calc(100% - 71px)'
                      }
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <DialogActions sx={{p: '24px 0 0!important'}}>
              <CuiCancelSaveButton
                onCancel={onCancelClick}
                loading={loading}
                typeSaveButton="submit"
                disableSave={loading}
                disableCancel={loading}
              />
            </DialogActions>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
