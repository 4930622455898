// @mui
import {Box} from '@mui/material'
// components
import {IconButtonAnimate} from '../../animate'

// ----------------------------------------------------------------------

type Props = {
  onToggleCollapse: VoidFunction
  collapseClick: boolean
}

export default function CollapseButton({
  onToggleCollapse,
  collapseClick
}: Props) {
  return (
    <IconButtonAnimate onClick={onToggleCollapse}>
      <Box
        sx={{
          lineHeight: 0,
          transition: theme =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter
            })
        }}
      >
        {collapseClick ? icon : openIcon}
      </Box>
    </IconButtonAnimate>
  )
}

// ----------------------------------------------------------------------

const openIcon = (
  <svg
    width="78"
    height="40"
    viewBox="0 0 78 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3335 8H9.3335C8.27263 8 7.25521 8.42143 6.50507 9.17157C5.75492 9.92172 5.3335 10.9391 5.3335 12V28C5.3335 29.0609 5.75492 30.0783 6.50507 30.8284C7.25521 31.5786 8.27263 32 9.3335 32H25.3335C25.6871 32 26.0263 31.8595 26.2763 31.6095C26.5264 31.3594 26.6668 31.0203 26.6668 30.6667V9.33333C26.6668 8.97971 26.5264 8.64057 26.2763 8.39052C26.0263 8.14048 25.6871 8 25.3335 8V8ZM9.3335 29.3333C8.97987 29.3333 8.64074 29.1929 8.39069 28.9428C8.14064 28.6928 8.00016 28.3536 8.00016 28C8.00016 27.6464 8.14064 27.3072 8.39069 27.0572C8.64074 26.8071 8.97987 26.6667 9.3335 26.6667H24.0002V29.3333H9.3335Z"
      fill="url(#paint0_linear_2731_22604)"
    />
    <path
      d="M46.534 27.18C45.862 27.18 45.196 27.096 44.536 26.928C43.882 26.76 43.279 26.505 42.727 26.163C42.181 25.821 41.731 25.392 41.377 24.876C41.023 24.354 40.819 23.742 40.765 23.04H44.329C44.395 23.346 44.524 23.607 44.716 23.823C44.908 24.039 45.157 24.207 45.463 24.327C45.769 24.441 46.12 24.498 46.516 24.498C46.876 24.498 47.215 24.462 47.533 24.39C47.857 24.318 48.118 24.198 48.316 24.03C48.52 23.856 48.622 23.616 48.622 23.31C48.622 23.046 48.517 22.842 48.307 22.698C48.103 22.554 47.836 22.443 47.506 22.365C47.182 22.287 46.84 22.218 46.48 22.158L44.491 21.816C43.495 21.654 42.691 21.252 42.079 20.61C41.473 19.962 41.158 19.086 41.134 17.982C41.122 17.106 41.356 16.359 41.836 15.741C42.322 15.117 42.97 14.643 43.78 14.319C44.596 13.989 45.496 13.824 46.48 13.824C47.668 13.824 48.673 13.998 49.495 14.346C50.317 14.694 50.941 15.177 51.367 15.795C51.799 16.413 52.024 17.124 52.042 17.928H48.568C48.532 17.592 48.43 17.322 48.262 17.118C48.094 16.908 47.866 16.758 47.578 16.668C47.296 16.572 46.954 16.524 46.552 16.524C46.216 16.524 45.934 16.557 45.706 16.623C45.484 16.683 45.307 16.764 45.175 16.866C45.043 16.962 44.947 17.07 44.887 17.19C44.833 17.31 44.806 17.43 44.806 17.55C44.806 17.844 44.899 18.081 45.085 18.261C45.271 18.441 45.643 18.582 46.201 18.684L48.199 19.026C49.129 19.188 49.858 19.44 50.386 19.782C50.914 20.118 51.301 20.499 51.547 20.925C51.799 21.351 51.958 21.774 52.024 22.194C52.09 22.614 52.123 22.986 52.123 23.31C52.123 24.048 51.877 24.711 51.385 25.299C50.899 25.881 50.236 26.34 49.396 26.676C48.556 27.012 47.602 27.18 46.534 27.18ZM62.0215 21.78H57.5305V27H54.0295V13.986H57.5305V18.918H62.0215V13.986H65.5225V27H62.0215V21.78ZM67.9513 13.986H71.4523V24.12H76.6543V27H67.9513V13.986Z"
      fill="url(#paint1_linear_2731_22604)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2731_22604"
        x1="-5.33317"
        y1="20"
        x2="18.5013"
        y2="41.1862"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5BE584" />
        <stop offset="1" stopColor="#007B55" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2731_22604"
        x1="21"
        y1="20"
        x2="47.7433"
        y2="56.2944"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5BE584" />
        <stop offset="1" stopColor="#007B55" />
      </linearGradient>
    </defs>
  </svg>
)

const icon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3335 4H9.3335C8.27263 4 7.25521 4.42143 6.50507 5.17157C5.75492 5.92172 5.3335 6.93913 5.3335 8V24C5.3335 25.0609 5.75492 26.0783 6.50507 26.8284C7.25521 27.5786 8.27263 28 9.3335 28H25.3335C25.6871 28 26.0263 27.8595 26.2763 27.6095C26.5264 27.3594 26.6668 27.0203 26.6668 26.6667V5.33333C26.6668 4.97971 26.5264 4.64057 26.2763 4.39052C26.0263 4.14048 25.6871 4 25.3335 4ZM9.3335 25.3333C8.97987 25.3333 8.64074 25.1929 8.39069 24.9428C8.14064 24.6928 8.00016 24.3536 8.00016 24C8.00016 23.6464 8.14064 23.3072 8.39069 23.0572C8.64074 22.8071 8.97987 22.6667 9.3335 22.6667H24.0002V25.3333H9.3335Z"
      fill="url(#paint0_linear_2731_22606)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2731_22606"
        x1="-5.33317"
        y1="16"
        x2="18.5013"
        y2="37.1862"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5BE584" />
        <stop offset="1" stopColor="#007B55" />
      </linearGradient>
    </defs>
  </svg>
)
