import React, {useCallback, useEffect, useState} from 'react'
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridToolbarQuickFilter,
  GridValueGetterParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import AddIcon from '@mui/icons-material/Add'
import {useSnackbar} from 'notistack'
import randomColor from 'randomcolor'
import config from 'src/config'
import ExaminationType, {Language} from 'src/entities/ExaminationType'
import {useAuth} from 'src/contexts/Auth'
import SvgIconStyle from 'src/components/SvgIconStyle'
import {getEnumOptionWithLabel} from 'src/utils/enumHelper'
import CuiOverflowTooltip from 'src/components/custom/CuiOverflowTooltip'
import TrackModal, {StatusModal} from 'src/components/shl/tracks/TrackModal'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import Option from 'src/entities/Option'
import {EmployeeWithColor} from 'src/entities/EmployeeWithColor'
import {useNavigate} from 'react-router'
import {globalSettingsColumn} from 'src/settings/type'
import useExaminationTypeList from 'src/hooks/useExaminationTypeList'
import PeopleAmountBox from 'src/components/shl/shared/PeopleAmountBox'
import EditIconButton from 'src/components/shl/shared/EditIconButton'

const LanguagesList = getEnumOptionWithLabel(Language)
const newTrack: ExaminationType = {
  examinationTypeID: 0,
  examinationTypeName: '',
  examinationTypeShortName: '',
  isActive: true,
  participantsNumber: 0,
  reviewers: []
}

export default function TrackList() {
  const [examTypeWithReviewersList, setExamTypeWithReviewersList] = useState<
    ExaminationType[]
  >([])
  const [loading, setLoading] = useState<boolean>(true)
  const [statusModal, setStatusModal] = useState<StatusModal>(StatusModal.Close)
  const [selectedRow, setSelectedRow] = useState<ExaminationType>(newTrack)
  const [trackIdForDelete, setTrackIdForDelete] = useState<number>()
  const [reviewerList, setReviewerList] = useState<EmployeeWithColor[]>()
  const apiRef = useGridApiRef()
  const navigate = useNavigate()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const {examinationTypeList, isExaminationTypeListLoading} =
    useExaminationTypeList()
  const columns: GridColDef[] = [
    {
      ...globalSettingsColumn,
      field: 'examinationTypeName',
      headerName: 'Name',
      flex: 0.18,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => (
        <CuiOverflowTooltip value={params.value} />
      )
    },
    {
      ...globalSettingsColumn,
      field: 'examinationTypeShortName',
      headerName: 'Short Name',
      flex: 0.14
    },
    {
      ...globalSettingsColumn,
      field: 'languageID',
      headerName: 'Language',
      flex: 0.08,
      valueGetter: (params: GridValueGetterParams) =>
        LanguagesList[params.value - 1].label
    },
    {
      ...globalSettingsColumn,
      field: 'participantsNumber',
      headerName: 'Participants',
      flex: 0.08,
      renderCell: (params: GridRenderCellParams) => (
        <PeopleAmountBox
          amount={params.value}
          onClick={() =>
            navigate(`/shl/participants/active/${params.row.examinationTypeID}`)
          }
        />
      )
    },
    {
      ...globalSettingsColumn,
      field: 'isOpenBook',
      headerName: 'Book Open/Closed',
      flex: 0.14,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? 'Open' : 'Closed'
    },
    {
      ...globalSettingsColumn,
      field: 'isOpenQuestions',
      headerName: 'Questions',
      flex: 0.14,
      valueGetter: (params: GridValueGetterParams) =>
        params.value ? 'Free text' : 'Multiple choice'
    },
    {
      ...globalSettingsColumn,
      field: 'reviewerNames',
      headerName: 'Reviewer',
      flex: 0.18,
      renderCell: (params: GridRenderCellParams) => (
        <CuiOverflowTooltip value={params.value.join(', ')} />
      )
    },
    {
      ...globalSettingsColumn,
      field: 'actions',
      headerName: '',
      flex: 0.1,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <Box width="100%" display="flex" px={1.5} justifyContent="space-around">
          <EditIconButton
            onClick={() => {
              setSelectedRow(params.row)
              setStatusModal(StatusModal.Update)
            }}
          />
          <Tooltip
            title={
              params.row.participantsNumber > 0 ? (
                <Box textAlign="center">
                  Only tracks without participants
                  <br /> can be deleted
                </Box>
              ) : (
                ''
              )
            }
          >
            <Box>
              <IconButton
                onClick={() => {
                  setTrackIdForDelete(params.row.examinationTypeID)
                }}
                disabled={params.row.participantsNumber > 0}
              >
                <SvgIconStyle
                  src="/assets/icons/ic_delete.svg"
                  sx={{
                    color:
                      params.row.participantsNumber > 0 ? '#637381' : '#212B36',
                    width: 20
                  }}
                />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      )
    }
  ]

  const getReviewerList = useCallback(() => {
    fetchWithUser(config.apiUrl + '/CodeTable/GetEmployeesNamesList')
      .then(res => res.json())
      .then((data: Option[]) => {
        setReviewerList(
          data.map(r => ({...r, color: randomColor({luminosity: 'bright'})}))
        )
        setLoading(false)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        setLoading(false)
        throw e
      })
  }, [fetchWithUser, enqueueSnackbar])

  const getExamTypeWithReviewersList = useCallback(() => {
    if (!isExaminationTypeListLoading && reviewerList) {
      const rows: ExaminationType[] = examinationTypeList.map(et => ({
        ...et,
        reviewerNames: reviewerList
          ?.filter(r => et.reviewers?.includes(r.key))
          .map(r => r.value)
      }))
      setExamTypeWithReviewersList(rows)
    }
  }, [isExaminationTypeListLoading, reviewerList, examinationTypeList])

  useEffect(getExamTypeWithReviewersList, [getExamTypeWithReviewersList])
  useEffect(getReviewerList, [getReviewerList])

  const removeTrack = () => {
    fetchWithUser(
      config.apiUrl +
        `/Examination/RemoveExaminationType/?examinationTypeId=${trackIdForDelete}`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'}
      }
    )
      .then(() => {
        setTrackIdForDelete(undefined)
        enqueueSnackbar('Your changes have been successfully saved')
        apiRef.current.updateRows([
          {examinationTypeID: trackIdForDelete, _action: 'delete'}
        ])
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }

  return (
    <>
      <Stack px={8} spacing={3} height="100%">
        <Stack spacing={3} direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="baseline" spacing={5}>
            <Typography fontWeight="bold" fontSize="x-large">
              Tracks
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                setSelectedRow(newTrack)
                setStatusModal(StatusModal.Create)
              }}
            >
              <AddIcon />
              New track
            </Button>
          </Stack>
        </Stack>
        <Stack spacing={3} height="100%" mt={'12px!important'}>
          <Box
            sx={{
              boxShadow:
                '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
              borderRadius: 2,
              width: '100%',
              position: 'relative',
              height: '100%'
            }}
          >
            <DataGridPro
              apiRef={apiRef}
              rows={examTypeWithReviewersList}
              columns={columns}
              experimentalFeatures={{newEditingApi: true}}
              autoPageSize
              disableExtendRowFullWidth
              headerHeight={42}
              rowHeight={47}
              components={{
                Toolbar: () => (
                  <GridToolbarQuickFilter
                    placeholder="Search track"
                    fullWidth
                    variant="outlined"
                    sx={{
                      mx: 3,
                      my: 2.5,
                      width: 'calc(100% - 48px)'
                    }}
                  />
                )
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  debounceMs: 300
                }
              }}
              getRowId={row => row.examinationTypeID}
              pageSize={30}
              loading={loading}
              sx={{
                '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
                  background: '#F4F6F8',
                  borderRadius: 1
                },
                '.MuiDataGrid-columnSeparator': {
                  display: 'none'
                },
                '.MuiDataGrid-withBorder': {
                  borderRight: 'none'
                }
              }}
            />
          </Box>
        </Stack>
      </Stack>
      <TrackModal
        examintionTypeForm={selectedRow}
        onCancelClick={() => {
          setStatusModal(StatusModal.Close)
        }}
        onSaveClick={(updates: ExaminationType) => {
          apiRef.current.updateRows([updates])
          setStatusModal(StatusModal.Close)
        }}
        statusModal={statusModal}
        setExamintionTypeForm={setSelectedRow}
        reviewerList={reviewerList}
      />
      <CuiAlertDialog
        fullWidth
        color="error"
        open={trackIdForDelete !== undefined}
        close={() => {
          setTrackIdForDelete(undefined)
        }}
        title="Delete a track?"
        body={
          <>
            You will not be able to restore the track later.
            <br />
            The events in the calendar will be deleted
          </>
        }
        okButtonText="Delete"
        okClick={removeTrack}
      />
    </>
  )
}
