import {matchPath, useLocation} from 'react-router'

export function useRouteMatch(patterns: readonly string[]) {
  const {pathname} = useLocation()
  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i]
    let possibleMatch = matchPath({path: pattern}, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }
  return null
}
