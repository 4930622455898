import {useState, useEffect} from 'react'
import {useSnackbar} from 'notistack'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'
import ExaminationType from 'src/entities/ExaminationType'

export default function useExaminationTypeList() {
  const [examinationTypeList, setExaminationTypeList] = useState<
    ExaminationType[]
  >([])
  const [isExaminationTypeListLoading, setIsExamTypeListLoading] =
    useState<boolean>(true)
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    fetchWithUser(config.apiUrl + `/Examination/GetAllExaminationTypeList`)
      .then(res => res.json())
      .then((data: ExaminationType[]) => {
        setExaminationTypeList(data)
        setIsExamTypeListLoading(false)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        setIsExamTypeListLoading(false)
        throw e
      })
  }, [fetchWithUser, enqueueSnackbar])

  return {examinationTypeList, isExaminationTypeListLoading}
}
