import React, {useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import {Typography, TextField, Stack} from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import randomColor from 'randomcolor'
import ParticipantModal from 'src/components/shl/participants/modals/ParticipantModal'
import EmployeeToSHL from 'src/entities/EmployeeToSHL'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import {EmployeeInfo} from 'src/entities/Employee'
import {fDate} from 'src/utils/formatTime'
import useExaminationTypeList from 'src/hooks/useExaminationTypeList'
import EditIconButton from 'src/components/shl/shared/EditIconButton'
import {ExaminToEmpType} from 'src/entities/EmployeeToExaminationCycleType'

interface UserInfoProps {
  participant: EmployeeInfo
  examinationList: ExaminToEmpType[]
  examinationOnTime: number
  examinationLate: number
}
interface InfoBoxProps {
  name: string
  value?: string | number | undefined
}

export function InfoBox({name, value}: InfoBoxProps) {
  return (
    <Typography height={70} component="div">
      {name}
      <Typography fontWeight="bold"> {value}</Typography>
    </Typography>
  )
}

export function TestStatusBox({name, value}: InfoBoxProps) {
  return (
    <Stack direction={'row'} marginTop={1}>
      <Typography height={30} width={80}>
        {name}
      </Typography>
      <Typography> {value}</Typography>
    </Stack>
  )
}

export default function UserInfoPage({
  participant,
  examinationList,
  examinationOnTime,
  examinationLate
}: UserInfoProps) {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const {examinationTypeList} = useExaminationTypeList()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const [employeeActiveShlCycle, setEmployeeActiveShlCycle] = useState<
    EmployeeToSHL | undefined
  >()
  const [selectedEmployeeToShl, setSelectedEmployeeToShl] =
    useState<EmployeeToSHL>()
  const [commentToEmpObj, setCommentToEmpObj] = useState({
    comment: participant?.commentToEmployee?.comment,
    commentToEmployeeID:
      participant?.commentToEmployee?.commentToEmployeeID || 0
  })

  const updateEmployeeToShl = (employeeToShl: EmployeeToSHL) => {
    const endpointUrl = '/SHLCycle/UpdateTrackToEmployee'
    return fetchWithUser(config.apiUrl + endpointUrl, {
      method: 'POST',
      body: JSON.stringify(employeeToShl),
      headers: {'Content-Type': 'application/json'}
    })
      .then(res => res.json())
      .then(() => {
        setEmployeeActiveShlCycle(employeeToShl)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }

  const addOrupdateCommentToEmployee = (comment: string) => {
    const endpointUrl = '/Employee/SaveCommentToEmployee'
    return fetchWithUser(config.apiUrl + endpointUrl, {
      method: 'POST',
      body: JSON.stringify({
        commentToEmployeeID: commentToEmpObj.commentToEmployeeID,
        employeeID: participant.employeeID,
        comment: comment
      }),
      headers: {'Content-Type': 'application/json'}
    })
      .then(res => res.json())
      .then(data => {
        if (commentToEmpObj.commentToEmployeeID === 0)
          setCommentToEmpObj({...commentToEmpObj, commentToEmployeeID: data})
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }

  useEffect(() => {
    const activeCycle = participant?.employeeToSHLCycles.find(
      item => item.isActive
    )

    setEmployeeActiveShlCycle(
      activeCycle
        ? {
            ...activeCycle,
            firstName: participant!.firstName,
            lastName: participant!.lastName,
            color: randomColor({luminosity: 'bright'})
          }
        : undefined
    )
  }, [participant])

  return (
    <>
      {participant && (
        <Stack direction={'row'} sx={{width: '100%', height: '100%', mt: 7}}>
          <Stack width={'30%'}>
            <InfoBox name={'Phone'} value={participant?.phone} />
            <InfoBox name={'Office'} value={participant?.office?.officeName} />
            {employeeActiveShlCycle?.examinationType
              ?.examinationTypeShortName && (
              <>
                <Typography height={70} component="div">
                  Track
                  <Typography fontWeight="bold">
                    {
                      employeeActiveShlCycle?.examinationType
                        ?.examinationTypeShortName
                    }
                    <EditIconButton
                      onClick={e => {
                        e.stopPropagation()
                        setIsOpenModal(true)
                        setSelectedEmployeeToShl(employeeActiveShlCycle)
                      }}
                    />
                  </Typography>
                </Typography>
              </>
            )}

            <InfoBox
              name={'JoinDate'}
              value={
                participant?.employeeToSHLCycles[0].joinDate &&
                fDate(participant.employeeToSHLCycles[0].joinDate)
              }
            />
            <Stack direction={'row'} height={70} gap={1}>
              <Typography width={120}>Joined with test:</Typography>
              <Typography fontWeight="bold">
                {participant?.employeeToSHLCycles[0].examinationOrderStart}
              </Typography>
            </Stack>
          </Stack>

          <Stack width={'20%'}>
            {participant?.lotteryWinners.length > 0 && (
              <Card>
                <CardContent>
                  <EmojiEventsIcon fontSize="large" />
                  <Typography>
                    {
                      participant?.lotteryWinners[0]?.lottery
                        ?.lotteryEnglishMame
                    }
                  </Typography>
                </CardContent>
              </Card>
            )}
            <Stack sx={{mt: 8}}>
              <Typography fontWeight="bold">Test submited:</Typography>
              <TestStatusBox name={'Total:'} value={examinationList.length} />
              <TestStatusBox name={'On time: '} value={examinationOnTime} />
              <TestStatusBox name={'Late:'} value={examinationLate} />
            </Stack>
          </Stack>

          <Stack
            width={participant?.employeeToSHLCycles.length > 1 ? '90%' : '50%'}
            sx={{ml: 18}}
          >
            {commentToEmpObj.comment && commentToEmpObj.comment.length > 0 ? (
              <Card>
                <CardContent>
                  <TextField
                    fullWidth
                    label="comment"
                    multiline
                    value={commentToEmpObj.comment}
                    onChange={({target}) =>
                      setCommentToEmpObj({
                        ...commentToEmpObj,
                        comment: target.value
                      })
                    }
                    rows={4}
                    onBlur={(e: any) =>
                      addOrupdateCommentToEmployee(e.target.value)
                    }
                  />
                </CardContent>
              </Card>
            ) : (
              <TextField
                sx={{ml: 3}}
                label="comment"
                multiline
                value={commentToEmpObj.comment}
                onChange={({target}) =>
                  setCommentToEmpObj({
                    ...commentToEmpObj,
                    comment: target.value
                  })
                }
                rows={4}
                onBlur={(e: any) =>
                  addOrupdateCommentToEmployee(e.target.value)
                }
              />
            )}

            {participant?.employeeToSHLCycles.length > 1 && (
              <Stack sx={{mt: 5, ml: 3}}>
                <Typography sx={{fontWeight: 'bold'}}>
                  Track History:
                </Typography>
                {participant.employeeToSHLCycles?.map((item, index) => (
                  <Stack direction={'row'} key={index} gap={5}>
                    <Stack width={250}>
                      <InfoBox
                        name={'Track '}
                        value={item.examinationType?.examinationTypeShortName}
                      />
                    </Stack>
                    <InfoBox
                      name={'JoinDate'}
                      value={item.joinDate && fDate(item.joinDate)}
                    />
                    <InfoBox
                      name={'ExitDate'}
                      value={item.exitDate && fDate(item.exitDate)}
                    />
                    <InfoBox
                      name={'Join On Test'}
                      value={item.examinationOrderStart}
                    />
                  </Stack>
                ))}
              </Stack>
            )}

            {participant?.babyBirthDate && (
              <Stack sx={{mt: 5, ml: 3}}>
                <Typography sx={{fontWeight: 'bold'}}>
                  Maternity leave:
                </Typography>
                <Stack direction={'row'} height={30} gap={1}>
                  <Typography width={170}>Leave date:</Typography>
                  <Typography width={350}>
                    {participant.mlStartDate && fDate(participant.mlStartDate)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} height={30} gap={1}>
                  <Typography width={170}>Number test at leave:</Typography>
                  <Typography width={350}>
                    {`Test ${participant.orderTestInLeaveToML}`}
                  </Typography>
                </Stack>
                {participant.returnDate && (
                  <Stack direction={'row'} height={30} gap={1}>
                    <Typography width={170}>Return date:</Typography>
                    <Typography width={350}>
                      {participant.returnDate && fDate(participant.returnDate)}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>

          <ParticipantModal
            employeeToShl={employeeActiveShlCycle}
            setEmployeeToShl={setSelectedEmployeeToShl}
            isOpenModal={isOpenModal}
            onCancelClick={() => setIsOpenModal(false)}
            onSaveClick={() => {
              return updateEmployeeToShl(selectedEmployeeToShl!).then(() => {
                setIsOpenModal(false)
              })
            }}
            levelList={examinationTypeList}
          />
        </Stack>
      )}
    </>
  )
}
