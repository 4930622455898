import React, {useCallback, useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import {Stack, Alert, Button} from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridToolbarQuickFilter,
  GridValueGetterParams,
  GridValueFormatterParams,
  useGridApiRef
} from '@mui/x-data-grid-pro'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {useAuth} from 'src/contexts/Auth'
import {
  RaffleScreenEnum,
  GridColumnRaffleEvent,
  LotteryEventTypeFromApi,
  convertFromApiToLotteryEventType
} from 'src/components/shl/raffles/rafflesUtils'
import config from 'src/config'
import LotteryEventType from 'src/entities/LotteryEventType'
import {globalSettingsColumnWithSort} from 'src/settings/type'
import CuiToolbar from 'src/components/custom/CuiToolbar'
import useInitalState from 'src/hooks/useInitalState'
import EditIconButton from 'src/components/shl/shared/EditIconButton'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import RaffleModal from 'src/components/shl/raffles/RaffleModal'
import {fDate, heDate} from 'src/utils/formatTime'
import {useNavigate} from 'react-router'

export default function RaffleList() {
  const [raffleList, setRaffleList] = useState<LotteryEventType[]>([])
  const [selectedRow, setSelectedRow] = useState<LotteryEventType>(
    {} as LotteryEventType
  )
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(true)
  const [isOpenModal, setIsOpenModal] = useState<boolean>()
  const apiRef = useGridApiRef()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const {initialState} = useInitalState(
    RaffleScreenEnum.Raffles,
    apiRef.current?.exportState,
    apiRef.current.restoreState,
    apiRef.current.setColumnVisibilityModel
  )

  const columns: GridColDef[] = [
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEvent.RaffleEvent,
      headerName: 'Raffle Event',
      flex: 0.15,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.lotteryEnglishMame
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEvent.RaffleDescription,
      headerName: 'Hebrow Date',
      type: 'date',
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.event.eventDate,
      valueFormatter: (params: GridValueFormatterParams) => heDate(params.value)
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEvent.EventDate,
      headerName: 'Date',
      type: 'date',
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) =>
        fDate(params.row.event.eventDate)
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEvent.RafflWinners,
      headerName: 'Winners',
      flex: 0.1,
      type: 'number',
      renderCell: (params: GridRenderCellParams) => (
        <Button>{params.row.lotteriesAmount}</Button>
      )
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEvent.RaffleEdit,
      headerName: 'Edit',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => (
        <EditIconButton
          onClick={e => {
            e.stopPropagation()
            setSelectedRow(params.row)
            setIsOpenModal(true)
          }}
        />
      )
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEvent.RaffleDelete,
      headerName: 'Delete',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => (
        <DeleteOutlineIcon
          onClick={e => {
            e.stopPropagation()
            setSelectedRow(params.row)
            setIsOpenDeleteAlert(true)
          }}
        />
      )
    },
    {
      ...globalSettingsColumnWithSort,
      field: GridColumnRaffleEvent.RaffleLink,
      headerName: 'Raffle Link',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          onClick={() =>
            window.open(
              `https://raffle.citybook.co.il/all-lotteries/${params.row.event.eventID}`
            )
          }
        >
          Raffle
        </Button>
      )
    }
  ]

  const [isOpenDeleteAlert, setIsOpenDeleteAlert] = useState<boolean>(false)

  const GetRafflesTypeList = useCallback(() => {
    fetchWithUser(`${config.apiUrl}/LiveLottery/GetLotteriesEvent`)
      .then(res => res.json())
      .then((data: LotteryEventTypeFromApi[]) => {
        const rows: LotteryEventType[] = data.map(r =>
          convertFromApiToLotteryEventType(r)
        )
        setRaffleList(rows)
        setLoading(false)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        setLoading(false)
        throw e
      })
  }, [fetchWithUser, enqueueSnackbar])

  useEffect(GetRafflesTypeList, [GetRafflesTypeList])

  const deleteRaffle = () => {
    fetchWithUser(
      `${config.apiUrl}/Event/DeleteEvent?eventID=${selectedRow.event.eventID}`,
      {
        method: 'POST',
        body: JSON.stringify({...selectedRow.event}),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(() => {
        enqueueSnackbar('The data saved success')
        apiRef.current.updateRows([
          {lotteryID: selectedRow.lotteryID, _action: 'delete'}
        ])
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again', {
          variant: 'error'
        })
        setLoading(false)
        throw e
      })
  }

  return (
    <>
      <Stack spacing={3} height="calc(100% - 84px)">
        <DataGridPro
          rows={raffleList}
          apiRef={apiRef}
          columns={columns}
          experimentalFeatures={{newEditingApi: true}}
          autoPageSize
          disableExtendRowFullWidth
          headerHeight={42}
          rowHeight={47}
          getRowId={row => row.lotteryID}
          pageSize={30}
          loading={loading || !initialState}
          onCellClick={param => {
            if (param.field === GridColumnRaffleEvent.RafflWinners) {
              navigate(`/shl/raffles/${param.row.lotteryID}/winners`, {
                state: {
                  winners: param.row.lotteryWinners,
                  raffleName: param.row.lotteryEnglishMame
                }
              })
            } else {
              if (
                param.field !== GridColumnRaffleEvent.RaffleLink &&
                param.field !== GridColumnRaffleEvent.RaffleDelete
              )
                navigate(`/shl/raffles/${param.row.lotteryID}/participant`, {
                  state: {
                    winners: param.row.lotteryWinners,
                    raffleName: param.row.lotteryEnglishMame
                  }
                })
            }
          }}
          components={{
            Toolbar: () => (
              <Stack>
                <CuiToolbar
                  screenId={RaffleScreenEnum.Raffles}
                  exportState={apiRef.current.exportState}
                  columnVisibilityModel={
                    apiRef.current.state?.columns.columnVisibilityModel
                  }
                  csvFileName={`Raffles `}
                />
                <GridToolbarQuickFilter
                  placeholder="Search Raffles"
                  fullWidth
                  variant="outlined"
                  sx={{
                    mx: 2,
                    my: 1,
                    height: 52,
                    width: 'calc(100% - 48px)',
                    '.MuiFormControl-root': {
                      height: '100%'
                    },
                    '.MuiOutlinedInput-root': {
                      height: '100%'
                    }
                  }}
                />
              </Stack>
            )
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              debounceMs: 300
            }
          }}
          sx={{
            '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
              background: '#F4F6F8',
              borderRadius: 1
            },
            '.MuiDataGrid-withBorder': {
              borderRight: 'none'
            }
          }}
        />
      </Stack>
      <RaffleModal
        onCancel={() => setIsOpenModal(false)}
        isOpen={isOpenModal ?? false}
        raffle={selectedRow}
        setRaffleList={apiRef.current.updateRows}
      />
      <CuiAlertDialog
        fullWidth
        color="error"
        open={isOpenDeleteAlert}
        close={() => {
          setIsOpenDeleteAlert(false)
        }}
        title={'Delete raffle'}
        body={<Alert severity="warning">The raffle will be deleted </Alert>}
        okButtonText={'Delete raffle'}
        okClick={deleteRaffle}
        canBeChange={selectedRow.isCanDeleted || false}
        cantBeChangeInfo={
          'It is not possible to delete a raffle that employees are already linked'
        }
      />
    </>
  )
}
