import {Route, Routes} from 'react-router-dom'
import {Box, Stack, Typography} from '@mui/material'
import RafflesList from 'src/components/shl/raffles/RafflesList'

export default function RafflesPage() {
  const RaffleListWithProps = <RafflesList />

  return (
    <Box sx={{width: '100%', height: '100%', px: 8}}>
      <Stack direction="row" alignItems="baseline" spacing={5}>
        <Typography fontWeight="bold" fontSize="large">
          Raffles
        </Typography>
      </Stack>

      <Routes>
        <Route path="/raffles" element={RaffleListWithProps} />
        <Route path="/*" element={RaffleListWithProps} />
      </Routes>
    </Box>
  )
}
