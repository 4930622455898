import { Dispatch, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Stack } from '@mui/material'
import { GridFilterModel, GridToolbarQuickFilter } from '@mui/x-data-grid-pro'
import CuiSelectWithOptions from 'src/components/custom/CuiSelectWithOptions'
import ExaminationType from 'src/entities/ExaminationType'
import { changeFilterData } from 'src/utils/externalFilter'
import { getEnumOptionWithKey } from 'src/utils/enumHelper'
import { SubmissionStatusEnum } from 'src/entities/EmployeeToExaminationCycleType'
import { GridColumnExaminess } from '../tests/testUtils'

export interface TrackFilterToolbarProps {
  trackList: ExaminationType[]
  selectedTrackName: string
  setFilterModel: Dispatch<React.SetStateAction<GridFilterModel>>
  urlForChangeModel: string
  selectedStatus?: SubmissionStatusEnum
  placeholderSearch?: string
}

export default function FilterToolbar({
  trackList,
  selectedTrackName,
  setFilterModel,
  urlForChangeModel,
  selectedStatus,
  placeholderSearch = 'Search'
}: TrackFilterToolbarProps) {
  const navigate = useNavigate()
  const { statusId, trackId } = useParams()
  const statusList = getEnumOptionWithKey(SubmissionStatusEnum).map(
    ({ key, value }) => ({
      label: value,
      value: key
    })
  )

  const onChangeFilterModel = useCallback(
    (
      columnField: string,
      value: string | number,
      operatorValue: string = 'contains'
    ) => {
      if (!trackList.length) return
      setFilterModel(p => ({
        ...p,
        items: changeFilterData(p.items, columnField, value, operatorValue)
      }))
      const newTrackId =
        columnField === GridColumnExaminess.Track
          ? value !== ''
            ? trackList.find(l => l.examinationTypeShortName === value)
              ?.examinationTypeID
            : undefined
          : trackId
      const newStatusId =
        columnField === GridColumnExaminess.SubmissionStatus ? value : statusId
      navigate(
        `${urlForChangeModel}${newTrackId ? `/${newTrackId}` : '/all'}${newStatusId ? `/${newStatusId}` : ''
        }`
      )
    },
    [setFilterModel, trackList, navigate, urlForChangeModel, statusId, trackId]
  )

  return (
    <Stack direction="row" mx={2} pb={1} mt={1} spacing={1} width="100%">
      <Stack width={300}>
        <CuiSelectWithOptions
          value={selectedTrackName}
          label="Track"
          options={trackList.map(l => ({
            value: l.examinationTypeShortName,
            label: l.examinationTypeShortName
          }))}
          fullWidth
          defaultValue={0}
          onChange={e =>
            onChangeFilterModel(
              GridColumnExaminess.Track,
              `${e.target.value}`,
              'contains'
            )
          }
          withAllOption
          sx={{ height: 48 }}
        />
      </Stack>
      {selectedStatus !== undefined && (
        <Stack width={300}>
          <CuiSelectWithOptions
            value={selectedStatus}
            label="Status"
            type="number"
            options={statusList.map(l => ({
              value: l.value,
              label: l.label
            }))}
            fullWidth
            defaultValue={0}
            onChange={e => {
              onChangeFilterModel(
                GridColumnExaminess.SubmissionStatus,
                e.target.value as number,
                'is'
              )
            }}
            withAllOption
            sx={{ height: 48 }}
          />
        </Stack>
      )}
      <GridToolbarQuickFilter
        placeholder={placeholderSearch}
        variant="outlined"
        sx={{
          width:
            selectedStatus !== undefined
              ? 'calc(100% - 500px)'
              : 'calc(100% - 264px)',
          height: 52,
          '.MuiFormControl-root': {
            height: '100%'
          },
          '.MuiOutlinedInput-root': {
            height: '100%'
          }
        }}
      />
    </Stack>
  )
}
