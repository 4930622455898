import React from 'react'
import {DialogTitle, IconButton, Stack} from '@mui/material'
import SvgIconStyle from 'src/components/SvgIconStyle'

interface CuiDialogTitleProps {
  title: string
  onCancelClick: () => void
}

export default function CuiDialogTitle({
  title,
  onCancelClick
}: CuiDialogTitleProps) {
  return (
    <DialogTitle>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pb={3}
      >
        {title}
        <IconButton onClick={onCancelClick}>
          <SvgIconStyle src="/assets/icons/ic_close.svg" />
        </IconButton>
      </Stack>
    </DialogTitle>
  )
}
