import React, {FormEvent, useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import {GridRowModelUpdate} from '@mui/x-data-grid-pro'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField
} from '@mui/material'
import {DateTimePicker} from '@mui/x-date-pickers'
import {useAuth} from 'src/contexts/Auth'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import config from 'src/config'
import LotteryEventType from 'src/entities/LotteryEventType'
import CuiDialogTitle from 'src/components/custom/CuiDialogTitle'

interface RaffleModalProps {
  onCancel: () => void
  isOpen: boolean
  raffle: LotteryEventType
  setRaffleList: (updates: GridRowModelUpdate[]) => void
}

export default function RaffleModal({
  onCancel,
  isOpen,
  raffle,
  setRaffleList
}: RaffleModalProps) {
  const [raffleForm, setRaffleForm] = useState<LotteryEventType>(
    {} as LotteryEventType
  )
  const [loading, setLoading] = useState<boolean>()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    setRaffleForm(raffle)
  }, [raffle])

  const submitForm = async (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault()
    setLoading(true)
    const body: LotteryEventType = {...raffleForm}
    fetchWithUser(`${config.apiUrl}/LiveLottery/UpdateLottery`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        accept: 'text/plain'
      }
    })
      .then(() => {
        enqueueSnackbar('The data saved success')
        setLoading(false)
        onCancel()
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again', {
          variant: 'error'
        })
        setLoading(false)
        throw e
      })
    setRaffleList([raffleForm])
  }

  return (
    <Dialog open={isOpen} fullWidth>
      <CuiDialogTitle
        title={
          raffleForm.event?.eventDate && raffleForm.event.eventDate > new Date()
            ? `Update Raffle ${raffle.event.eventName}`
            : 'It is not possible to update a raffle that has already completed '
        }
        onCancelClick={onCancel}
      />
      {raffleForm.event?.eventDate && raffleForm.event.eventDate > new Date() && (
        <DialogContent>
          <Stack mt={1}>
            <form onSubmit={submitForm}>
              <Grid container height={'medium'} rowSpacing={3}>
                <Grid item xs={12}>
                  <TextField
                    name="raffle"
                    label="Raffle Name"
                    fullWidth
                    value={raffleForm.lotteryEnglishMame ?? ''}
                    onChange={({target}) =>
                      setRaffleForm(prev => ({
                        ...prev,
                        lotteryEnglishMame: target.value
                      }))
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="winners"
                    label="Winners"
                    fullWidth
                    value={raffleForm.lotteriesAmount ?? ''}
                    onChange={({target}) =>
                      setRaffleForm(prev => ({
                        ...prev,
                        lotteriesAmount: +target.value
                      }))
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateTimePicker
                    views={['year', 'month', 'day']}
                    renderInput={props => (
                      <TextField {...props} fullWidth required />
                    )}
                    label="Date"
                    value={raffleForm.event.eventDate ?? new Date()}
                    inputFormat="dd/MM/yyyy"
                    onChange={newValue => {
                      setRaffleForm(prev => ({
                        ...prev,
                        event: {
                          ...prev.event,
                          eventDate: newValue ?? new Date()
                        }
                      }))
                    }}
                  />
                </Grid>
              </Grid>
              <DialogActions
                sx={{padding: '24px 0 0 0!important', marginTop: 5}}
              >
                <CuiCancelSaveButton
                  onCancel={onCancel}
                  loading={loading}
                  typeSaveButton="submit"
                  disableSave={loading}
                  disableCancel={loading}
                />
              </DialogActions>
            </form>
          </Stack>
        </DialogContent>
      )}
    </Dialog>
  )
}
