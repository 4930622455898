// components
import SvgIconStyle from '../../SvgIconStyle'

// ----------------------------------------------------------------------

export const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{width: 1, height: 1}}
  />
)

const ICONS = {
  charts: getIcon('ic_charts'),
  booking: getIcon('ic_booking'),
  adjust: getIcon('ic_adjust'),
  userGroup: getIcon('ic_user_group'),
  invoice: getIcon('ic_invoice'),
  trophy: getIcon('ic_trophy'),
  book: getIcon('ic_book')
}

const navConfig = [
  {
    subheader: '',
    items: [
      {title: 'Dashboard', path: '/shl/dashboard', icon: ICONS.charts},
      {title: 'Calendar', path: '/shl/calendar', icon: ICONS.booking},
      {title: 'Tracks', path: '/shl/tracks', icon: ICONS.adjust},
      {title: 'Participants', path: '/shl/participants', icon: ICONS.userGroup},
      {title: 'Tests', path: '/shl/tests', icon: ICONS.invoice},
      {title: 'Raffles', path: '/shl/raffles', icon: ICONS.trophy},
      {title: 'Siyum', path: '/shl/siyum', icon: ICONS.book}
    ]
  }
]

export default navConfig
