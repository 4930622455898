import React from 'react'
import {Box} from '@mui/material'
import SvgIconStyle from 'src/components/SvgIconStyle'

interface PeopleAmountColumnProps {
  amount: number | JSX.Element
  onClick?: () => void
  icon?: JSX.Element | null
  color?: string
}

export default function PeopleAmountBox({
  amount,
  onClick,
  icon,
  color = 'primary.main'
}: PeopleAmountColumnProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '22px',
        color: color,
        width: 50,
        height: '100%',
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      {icon ?? icon !== null ? (
        <SvgIconStyle
          src="/assets/icons/navbar/ic_user_group.svg"
          sx={{bgcolor: color, mr: 1}}
        />
      ) : null}
      <span>{amount}</span>
    </Box>
  )
}
