import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {useSnackbar} from 'notistack'
import {GridToolbarQuickFilter} from '@mui/x-data-grid'
import {Box, Stack, Typography, Alert} from '@mui/material'
import {
  DataGridPro,
  useGridApiRef,
  gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid-pro'
import {useAuth} from 'src/contexts/Auth'
import {ExaminationCycle} from 'src/entities/ExaminationCycle'
import testColumns, {
  GridColumnTests,
  convertFromApiToExaminationCycle,
  ExaminationCycleFromApi,
  TestScreenEnum
} from 'src/components/shl/tests/testUtils'
import config from 'src/config'
import CuiToolbar from 'src/components/custom/CuiToolbar'
import useInitalState from 'src/hooks/useInitalState'
import DataGridTotalRows from 'src/components/shl/shared/DataGridTotalRows'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import TestModal from 'src/components/shl/tests/TestModal'

export default function TestList() {
  const [examinationCycleList, setExaminationCycleList] = useState<
    ExaminationCycle[]
  >([])
  const [selectedRow, setSelectedRow] = useState<ExaminationCycle>(
    {} as ExaminationCycle
  )
  const [isOpenModal, setIsOpenModal] = useState<boolean>()
  const [isOpenChangeLockAlert, setIsOpenChangeLockAlert] =
    useState<boolean>(false)
  const [selectedTest, setSelectedTest] = useState<ExaminationCycle>()
  const [loading, setLoading] = useState<boolean>(true)
  const apiRef = useGridApiRef()
  const navigate = useNavigate()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const {initialState} = useInitalState(
    TestScreenEnum.ExaminationCycles,
    apiRef.current?.exportState,
    apiRef.current.restoreState,
    apiRef.current.setColumnVisibilityModel
  )

  const GetExaminationCycleList = useCallback(() => {
    fetchWithUser(config.apiUrl + `/Examination/GetExaminationCycleList`)
      .then(res => res.json())
      .then((data: ExaminationCycleFromApi[]) => {
        const rows: ExaminationCycle[] = data.map(ec =>
          convertFromApiToExaminationCycle(ec)
        )
        setExaminationCycleList(rows)
        setLoading(false)
      })
      .catch(() => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        setLoading(false)
      })
  }, [fetchWithUser, enqueueSnackbar])

  const onLockTest = async (test: ExaminationCycle) => {
    setSelectedTest({
      ...test,
      isLock: !test.isLock
    })
    setIsOpenChangeLockAlert(true)
  }

  const onEditTest = async (test: ExaminationCycle) => {
    setSelectedRow(test)
    setIsOpenModal(true)
  }

  const lockRaffle = () => {
    updateTest(selectedTest!).finally(() => {
      setIsOpenChangeLockAlert(false)
    })
  }

  const updateTest = (test: ExaminationCycle) => {
    const oldRow = apiRef.current.getRow(test.examinationCycleID)
    apiRef.current.updateRows([test])

    return fetchWithUser(
      `${config.apiUrl}/Examination/UpdateExaminationCycle`,
      {
        method: 'POST',
        body: JSON.stringify({...test, employee: null}),
        headers: {'Content-Type': 'application/json'}
      }
    ).catch(e => {
      apiRef.current.updateRows([oldRow])
      enqueueSnackbar('Internal server error, Please try again later', {
        variant: 'error'
      })
      throw e
    })
  }

  useEffect(() => {
    GetExaminationCycleList()
  }, [GetExaminationCycleList])

  return (
    <>
      <Stack px={8} spacing={3} height="100%">
        <Stack spacing={3} direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="baseline" spacing={5}>
            <Typography fontWeight="bold" fontSize="x-large">
              Tests
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={3} height="100%" mt="12px">
          <Box
            sx={{
              boxShadow:
                '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
              borderRadius: 2,
              width: '100%',
              position: 'relative',
              height: '100%'
            }}
          >
            <DataGridPro
              apiRef={apiRef}
              rows={examinationCycleList}
              columns={testColumns(onLockTest, onEditTest)}
              experimentalFeatures={{newEditingApi: true}}
              autoPageSize
              disableExtendRowFullWidth
              headerHeight={42}
              rowHeight={47}
              getRowId={row => row.examinationCycleID}
              pageSize={30}
              loading={loading || !initialState}
              onCellClick={param => {
                if (
                  param.field !== GridColumnTests.IsLock &&
                  param.field !== GridColumnTests.Edit
                ) {
                  navigate(`/shl/tests/${param.row.examinationCycleID}/tracks`)
                }
              }}
              components={{
                Toolbar: () => (
                  <Stack>
                    <CuiToolbar
                      screenId={TestScreenEnum.ExaminationCycles}
                      exportState={apiRef.current.exportState}
                      columnVisibilityModel={
                        apiRef.current.state?.columns.columnVisibilityModel
                      }
                      csvFileName={`Tests`}
                    />
                    <GridToolbarQuickFilter
                      placeholder="Search test"
                      fullWidth
                      variant="outlined"
                      sx={{
                        mx: 2,
                        my: 1.5,
                        width: 'calc(100% - 48px)'
                      }}
                    />
                  </Stack>
                ),
                Footer: () => (
                  <DataGridTotalRows
                    amount={gridFilteredSortedRowIdsSelector(apiRef).length}
                  />
                )
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  debounceMs: 300
                }
              }}
              sx={{
                '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
                  background: '#F4F6F8',
                  borderRadius: 1
                },
                '.MuiDataGrid-withBorder': {
                  borderRight: 'none'
                },
                '.MuiDataGrid-row': {
                  cursor: 'pointer'
                }
              }}
            />
          </Box>
        </Stack>
      </Stack>

      <TestModal
        onCancel={() => setIsOpenModal(false)}
        isOpen={isOpenModal ?? false}
        test={selectedRow}
        setTestList={apiRef.current.updateRows}
      />

      <CuiAlertDialog
        fullWidth
        color="error"
        open={isOpenChangeLockAlert}
        close={() => {
          setIsOpenChangeLockAlert(false)
        }}
        title={selectedTest?.isLock ? 'Lock Test ' : 'Un Lock Test '}
        body={
          selectedTest?.isLock ? (
            <>The test will be locked</>
          ) : (
            <Alert severity="warning">The test will be unlocked </Alert>
          )
        }
        okButtonText={selectedTest?.isLock ? 'Lock' : 'Un Lock'}
        okClick={lockRaffle}
      />
    </>
  )
}
