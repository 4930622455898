import {Box, Typography, Stack, useTheme, useMediaQuery} from '@mui/material'
import UploadIllustration from 'src/assets/illustration_upload'

export default function BlockContent() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{xs: 'column', md: 'row'}}
      maxHeight={117}
      sx={{width: 1, textAlign: {xs: 'center', md: 'left'}}}
    >
      <UploadIllustration
        sx={{width: 140, display: matches ? 'none' : 'block'}}
      />
      <Box sx={{p: 3}}>
        <Typography gutterBottom variant="subtitle2">
          Drop or Select file
        </Typography>

        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          Drop files here or click&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{color: 'primary.main', textDecoration: 'underline'}}
          >
            browse
          </Typography>
        </Typography>
      </Box>
    </Stack>
  )
}
