import React, {
  useState,
  useReducer,
  useContext,
  useEffect,
  useCallback
} from 'react'
import {Logger, UserAgentApplication, Account} from 'msal'
import {User} from '@microsoft/microsoft-graph-types'
import config from 'src/config'
import popupWindow from 'src/utils/popupWindow'
import Employee from 'src/entities/Employee'

interface AuthContextValue {
  user: User | null
  employee: Employee | null
  logout: () => void
  login: () => void
  isLoggingIn: boolean
  isFetchingUser: boolean
  isMsalAuth: boolean
  isAuth: boolean
  roles: string[]
  fetchWithUser: (
    endpoint: RequestInfo,
    options?: RequestInit,
    scopes?: string[]
  ) => Promise<Response>
  loginError: string
  shouldLogout: boolean
}

interface State {
  isMsalAuth: boolean
  isAuth: boolean
  roles: string[]
  isLoggingIn: boolean
  isFetchingUser: boolean
  user: User | null
  employee: Employee | null
  loginError: string
  tokenError: string
  shouldLogout: boolean
}

type Action =
  | {type: 'DID_LOGOUT'}
  | {type: 'WILL_LOGIN'}
  | {type: 'DID_LOGIN'; data: string[]}
  | {type: 'TOKEN_DID_ACQUIRED'}
  | {type: 'DID_LOGIN_ERROR'; data: string}
  | {type: 'USER_WILL_FETCH'}
  | {type: 'USER_DID_FETCH'; data: {user: User; employee: Employee}}
  | {type: 'USER_DID_FETCH_ERROR'; data: string}
  | {type: 'SHOULD_LOGOUT'; data: string}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'DID_LOGOUT':
      return {...state, isAuth: false, roles: [], isMsalAuth: false, user: null}
    case 'WILL_LOGIN':
      return {...state, isLoggingIn: true, loginError: ''}
    case 'DID_LOGIN':
      return {
        ...state,
        roles: action.data,
        isMsalAuth: true,
        isFetchingUser: true
      }
    case 'DID_LOGIN_ERROR':
      return {
        ...state,
        isLoggingIn: false,
        isAuth: false,
        roles: [],
        isMsalAuth: false,
        loginError: action.data
      }
    case 'TOKEN_DID_ACQUIRED':
      return {...state, isAuth: true, isLoggingIn: false, shouldLogout: false}
    case 'USER_WILL_FETCH':
      return {...state, isFetchingUser: true}
    case 'USER_DID_FETCH':
      return {
        ...state,
        user: action.data.user,
        employee: action.data.employee,
        isFetchingUser: false
      }
    case 'USER_DID_FETCH_ERROR':
      return {...state, isFetchingUser: false}
    case 'SHOULD_LOGOUT':
      return {
        ...state,
        shouldLogout: true,
        isAuth: false,
        roles: [],
        isMsalAuth: false,
        isLoggingIn: false,
        tokenError: action.data
      }
    default:
      return state
  }
}

const initialState: State = {
  isMsalAuth: false,
  isAuth: false,
  roles: [],
  isLoggingIn: false,
  isFetchingUser: false,
  user: null,
  employee: null,
  loginError: '',
  tokenError: '',
  shouldLogout: false
}

const scope = {
  graph: ['user.read'],
  api: [config.AADConfig.clientId + '/User.Read']
}

const AuthContext = React.createContext({} as AuthContextValue)

const requestState = JSON.stringify({
  redirectUrl:
    typeof window !== 'undefined' ? window.location.origin + '/msal.html' : ''
})

const getRoles = (account: Account) =>
  account?.idToken?.roles as any as string[]

function AuthContextProvider({children}: any) {
  const [msal] = useState(() => {
    return new UserAgentApplication({
      auth: {
        redirectUri: config.AADRedirectUrl,
        postLogoutRedirectUri: window.location.href,
        ...config.AADConfig
      },
      cache: {cacheLocation: 'localStorage'},
      system: {
        logger: new Logger((level, message) => {
          if (!config.isProduction) console.log('[msal]', level, message)
        })
      }
    })
  })

  const [
    {
      isAuth,
      roles,
      isLoggingIn,
      isFetchingUser,
      user,
      employee,
      loginError,
      shouldLogout,
      isMsalAuth
    },
    dispatch
  ] = useReducer(reducer, {
    ...initialState,
    isMsalAuth: msal && !!msal.getAccount(),
    roles: getRoles(msal?.getAccount()),
    isFetchingUser: msal && !!msal.getAccount()
  })

  const login = useCallback(async () => {
    dispatch({type: 'WILL_LOGIN'})
    try {
      // Login via popup
      await msal.loginPopup({
        scopes: scope.graph,
        prompt: 'select_account',
        state: requestState
      })
      dispatch({type: 'DID_LOGIN', data: getRoles(msal.getAccount())})
    } catch (err: any) {
      dispatch({type: 'DID_LOGIN_ERROR', data: err.errorMessage})
    }
  }, [msal])

  const logout = useCallback(() => {
    if (window.opener && window.opener !== window) return msal.logout()

    const logoutWin = popupWindow('/logout', '', 500, 600)
    if (logoutWin)
      logoutWin.onbeforeunload = () => dispatch({type: 'DID_LOGOUT'})
  }, [msal])

  const getAccessToken = useCallback(
    async (scopes: string[]): Promise<string> => {
      const tokenrequest = {
        scopes: scopes,
        state: requestState
      }
      try {
        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        var silentResult = await msal.acquireTokenSilent(tokenrequest)
        return silentResult.accessToken
      } catch (err: any) {
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        if (
          err.name === 'InteractionRequiredAuthError' ||
          isInteractionRequired(err)
        ) {
          try {
            var interactiveResult = await msal.acquireTokenPopup(tokenrequest)
            return interactiveResult.accessToken
          } catch {
            dispatch({type: 'SHOULD_LOGOUT', data: err})
            return ''
          }
        } else {
          dispatch({type: 'SHOULD_LOGOUT', data: err})
          return ''
        }
      }
    },
    [msal]
  )

  const fetchWithUser = useCallback(
    async (
      endpoint: RequestInfo,
      options: RequestInit = {},
      scopes: string[] = scope.api
    ): Promise<Response> => {
      var accessToken = await getAccessToken(scopes)

      const headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`
      }
      const mergedOpts = {...options, headers}

      return fetch(endpoint, mergedOpts).then(response => {
        if (!response.ok) {
          return Promise.reject(response)
        }
        return response
      })
    },
    [getAccessToken]
  )

  const getEmployee = useCallback(
    (email: string) => {
      return fetchWithUser(
        config.apiUrl + `/Employee/GetEmployeeByEmail/${email}`
      )
        .then(res => res.json())
        .catch(e => {
          dispatch({type: 'DID_LOGIN_ERROR', data: e.errorMessage})
          return null
        })
    },
    [fetchWithUser]
  )

  const getUserProfile = useCallback(async () => {
    dispatch({type: 'USER_WILL_FETCH'})
    const res = await fetchWithUser(
      'https://graph.microsoft.com/v1.0/me',
      {},
      scope.graph
    )
    const {error, ...user} = await res.json()

    if (error) return dispatch({type: 'USER_DID_FETCH_ERROR', data: error})
    const employee = await getEmployee(user.mail)
    return dispatch({
      type: 'USER_DID_FETCH',
      data: {user: user, employee: employee}
    })
  }, [fetchWithUser, getEmployee])

  const isInteractionRequired = (error: Error): boolean => {
    if (!error.message || error.message.length <= 0) {
      return false
    }

    return (
      error.message.indexOf('consent_required') > -1 ||
      error.message.indexOf('interaction_required') > -1 ||
      error.message.indexOf('login_required') > -1
    )
  }

  useEffect(() => {
    if (isAuth) getUserProfile()
  }, [isAuth, getUserProfile])

  useEffect(() => {
    ;(async () => {
      isMsalAuth &&
        (await getAccessToken(scope.graph)) &&
        dispatch({type: 'TOKEN_DID_ACQUIRED'})
    })()
  }, [isMsalAuth, getAccessToken])

  const value: AuthContextValue = {
    user,
    employee,
    isMsalAuth,
    isAuth,
    roles,
    login,
    logout,
    isLoggingIn,
    isFetchingUser,
    fetchWithUser: fetchWithUser,
    loginError,
    shouldLogout
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

export {AuthContextProvider, useAuth}
