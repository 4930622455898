import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  TextField
} from '@mui/material'
import CuiCancelSaveButton from 'src/components/custom/CuiCancelSaveButton'
import Scrollbar from 'src/components/Scrollbar'
import {useAuth} from 'src/contexts/Auth'
import config from 'src/config'
import Employee from 'src/entities/Employee'
import {useSnackbar} from 'notistack'
import randomColor from 'randomcolor'
import CuiDialogTitle from 'src/components/custom/CuiDialogTitle'

interface InviteParticipantModalProps {
  isOpen: boolean
  onCancelClick: () => void
}

export const InviteParticipantModal = forwardRef(
  ({isOpen, onCancelClick}: InviteParticipantModalProps, ref) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingButton, setLoadingButton] = useState<boolean>(false)
    const [searchEmployee, setSearchEmployee] = useState<string>()
    const [employeeToInvited, setEmployeeToInvited] = useState<number[]>([])
    const employeeList = useRef<Employee[]>([])
    const {fetchWithUser} = useAuth()
    const {enqueueSnackbar} = useSnackbar()
    const filterEmployeeList: Employee[] = employeeList.current.filter(e =>
      `${e.lastName} ${e.firstName}`
        .toLowerCase()
        .includes(searchEmployee?.toLowerCase() ?? '')
    )

    useEffect(() => {
      if (employeeList.current.length === 0) {
        fetchWithUser(
          config.apiUrl + '/SHLCycle/GetEmployeeListNotInCurrentSHLCycle'
        )
          .then(res => res.json())
          .then((data: Employee[]) => {
            employeeList.current = data.map(e => ({
              ...e,
              color: randomColor()
            }))
            setLoading(false)
          })
          .catch(e => {
            enqueueSnackbar('Internal server error, Please try again later', {
              variant: 'error'
            })
            setLoading(false)
            throw e
          })
      }
    }, [employeeList, enqueueSnackbar, fetchWithUser])

    useImperativeHandle(ref, () => ({
      deleteEmployeeFromInviteList(employeeId: number) {
        employeeList.current = employeeList.current.filter(
          e => e.employeeID !== employeeId
        )
      },
      addEmployeeToInviteList(employee: Employee) {
        employeeList.current.push(employee)
      }
    }))

    const submitModal = () => {
      setLoadingButton(true)
      fetchWithUser(config.apiUrl + '/SHLCycle/InviteEmployeesToSHLCycle', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(employeeToInvited)
      })
        .then(() => {
          enqueueSnackbar('The invitation has been sent successfully')
          setLoadingButton(false)
          employeeList.current = employeeList.current.filter(
            e => !employeeToInvited.includes(e.employeeID)
          )
          onCancel()
        })
        .catch(e => {
          enqueueSnackbar('Internal server error, Please try again later', {
            variant: 'error'
          })
          setLoadingButton(false)
          throw e
        })
    }

    const onCancel = () => {
      setSearchEmployee(undefined)
      onCancelClick()
    }

    return (
      <Dialog open={isOpen} fullWidth>
        <CuiDialogTitle
          title="Invite someone to join SHL program"
          onCancelClick={onCancelClick}
        />
        <DialogContent sx={{pb: 0}}>
          <Stack spacing={3} mt={1}>
            <TextField
              label="Search"
              onChange={({target}) => setSearchEmployee(target.value)}
            />
            <List
              dense
              sx={{width: 552, height: 240, bgcolor: 'background.paper'}}
            >
              <Scrollbar>
                {filterEmployeeList.length > 0 ? (
                  filterEmployeeList.map(employee => {
                    const labelId = `checkbox-list-secondary-label-${employee}`
                    return (
                      <ListItem
                        key={employee.employeeID}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            checked={
                              employeeToInvited.includes(employee.employeeID) ||
                              false
                            }
                            inputProps={{'aria-labelledby': labelId}}
                          />
                        }
                        onClick={() =>
                          setEmployeeToInvited(val =>
                            employeeToInvited.includes(employee.employeeID)
                              ? val.filter(e => e !== employee.employeeID)
                              : [...val, employee.employeeID]
                          )
                        }
                        disablePadding
                      >
                        <ListItemButton>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                fontSize: '0.875rem',
                                mr: 2,
                                backgroundColor: employee.color,
                                color: '#fff'
                              }}
                            >
                              {employee.lastName[0]}
                              {employee.firstName[0]}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            id={labelId}
                            primary={`${employee.lastName} ${employee.firstName}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  })
                ) : (
                  <Box textAlign="center">
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'No search results'
                    )}
                  </Box>
                )}
              </Scrollbar>
            </List>
          </Stack>
        </DialogContent>
        <DialogActions>
          <CuiCancelSaveButton
            onCancel={onCancelClick}
            disableSave={loading || employeeToInvited.length === 0}
            loading={loadingButton}
            onSave={submitModal}
            saveButtonText="Invite"
          />
        </DialogActions>
      </Dialog>
    )
  }
)

export default InviteParticipantModal
