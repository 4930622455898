import React, {useRef, useState} from 'react'
import {Link, matchPath, useLocation, useParams} from 'react-router-dom'
import {Box, Button, Stack, Tab, Tabs} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ParticipantsPage from 'src/components/shl/participants/ParticipantsPage'
import InviteParticipantModal from 'src/components/shl/participants/modals/InviteParticipantModal'
import {
  ParticipantsTypeEnum,
  ParticipantsTypeKeys
} from 'src/components/shl/participants/participantsUtils'

function useRouteMatch(patterns: readonly string[]) {
  const {pathname} = useLocation()
  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i]
    let possibleMatch = matchPath({path: pattern}, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }
  return null
}

export default function TabsRouter() {
  const [isOpenInviteParticipant, setIsOpenInviteParticipant] =
    useState<boolean>(false)
  const {type} = useParams()
  const inviteParticipantRef = useRef<any>(null)
  const routeMatch = useRouteMatch([
    '/shl/participants/invited',
    '/shl/participants/reactive/*',
    '/shl/participants/ml',
    '/shl/participants/*'
  ])
  const currentTab = routeMatch?.pattern?.path
  const participantType = (
    type ? type.charAt(0).toUpperCase() + type.slice(1) : 'Active'
  ) as ParticipantsTypeKeys

  return (
    <>
      <Box sx={{width: '100%'}}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="end"
          justifyContent="space-between"
          pr={2}
        >
          <Tabs value={currentTab}>
            <Tab
              label="Active Participants"
              value="/shl/participants/*"
              to="/shl/participants/active"
              component={Link}
            />
            <Tab
              label="Nonactive Participants"
              value="/shl/participants/reactive/*"
              to="/shl/participants/reactive"
              component={Link}
            />
            <Tab
              label="Invited Participants"
              value="/shl/participants/invited"
              to="/shl/participants/invited"
              component={Link}
            />
            <Tab
              label="ML Participants"
              value="/shl/participants/ml"
              to="/shl/participants/ml"
              component={Link}
            />
          </Tabs>
          <Button
            variant="contained"
            size="medium"
            sx={{height: 36}}
            onClick={() => {
              setIsOpenInviteParticipant(true)
            }}
          >
            <AddIcon />
            Invite Participant
          </Button>
        </Stack>
        <ParticipantsPage
          participantsType={ParticipantsTypeEnum[participantType]}
          deleteEmployeeFromInviteList={
            inviteParticipantRef.current?.deleteEmployeeFromInviteList
          }
          addEmployeeToInviteList={
            inviteParticipantRef.current?.addEmployeeToInviteList
          }
        />
      </Box>
      <InviteParticipantModal
        ref={inviteParticipantRef}
        isOpen={isOpenInviteParticipant}
        onCancelClick={() => setIsOpenInviteParticipant(false)}
      />
    </>
  )
}
