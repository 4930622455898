import {Grid, TextField} from '@mui/material'
import {DateTimePicker} from '@mui/x-date-pickers'
import {ExaminationCycle} from 'src/entities/ExaminationCycle'

interface DateTimePickerModalProps {
  label: string
  value: Date | undefined
  propertyName: string
  setTestForm: (value: React.SetStateAction<ExaminationCycle>) => void
}

export default function DateTimePickerModal({
  label,
  value,
  propertyName,
  setTestForm
}: DateTimePickerModalProps) {
  return (
    <Grid item xs={12}>
      <DateTimePicker
        renderInput={props => <TextField {...props} fullWidth required />}
        label={label}
        value={value ?? new Date()}
        inputFormat="dd/MM/yyyy HH:mm"
        onChange={newValue => {
          setTestForm(prev => ({
            ...prev,
            [propertyName]: newValue ?? new Date()
          }))
        }}
      />
    </Grid>
  )
}
