import React, {useCallback, useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import {useParams} from 'react-router'
import {Stack} from '@mui/material'
import {
  DataGridPro,
  GridCellEditStopParams,
  GridFilterModel,
  GridLinkOperator,
  useGridApiRef,
  gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid-pro'
import DataGridTotalRows from 'src/components/shl/shared/DataGridTotalRows'
import config from 'src/config'
import {useAuth} from 'src/contexts/Auth'
import {
  examineeColumns,
  GridColumnExaminess,
  TestScreenEnum
} from 'src/components/shl/tests/testUtils'
import {getLocalDateFromString} from 'src/utils/formatTime'
import {EmployeeToExaminationCycleType} from 'src/entities/EmployeeToExaminationCycleType'
import {changeFilterData} from 'src/utils/externalFilter'
import useExaminationTypeList from 'src/hooks/useExaminationTypeList'
import TestExamineeModal from 'src/components/shl/tests/testExaminees/TestExamineeModal'
import TestExamineeToolbar from 'src/components/shl/tests/testExaminees/TestExamineeToolbar'
import useInitalState from 'src/hooks/useInitalState'
import ExaminationCycleType from 'src/entities/ExaminationCycleType'

interface TestExamineeListProps {
  isOpenAddExamineeDialog: boolean
  setIsOpenAddExamineeDialog: (value: boolean) => void
  examinationOrder?: number
}

export function TestExamineeList({
  isOpenAddExamineeDialog,
  setIsOpenAddExamineeDialog,
  examinationOrder
}: TestExamineeListProps) {
  const [examineesList, setExamineesList] = useState<
    EmployeeToExaminationCycleType[]
  >([])
  const [loading, setLoading] = useState<boolean>()
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    linkOperator: GridLinkOperator.And
  })
  const [testTrakMissQNList, setTestTrakMissQNList] =
    useState<ExaminationCycleType[]>()
  const {fetchWithUser} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const {id, trackId, statusId} = useParams()
  const apiRef = useGridApiRef()
  const {examinationTypeList, isExaminationTypeListLoading} =
    useExaminationTypeList()
  const {initialState} = useInitalState(
    TestScreenEnum.Examinees,
    apiRef.current?.exportState,
    apiRef.current.restoreState,
    apiRef.current.setColumnVisibilityModel
  )

  const getExamineesList = useCallback(() => {
    setLoading(true)
    fetchWithUser(
      `${config.apiUrl}/Examination/GetEmpToExamCycTypeListByExamCycId/${id}`
    )
      .then(res => res.json())
      .then(
        (
          data: (EmployeeToExaminationCycleType & {submissionTime?: string})[]
        ) => {
          const rows: EmployeeToExaminationCycleType[] = data.map(ec => ({
            ...ec,
            submissionTime: getLocalDateFromString(ec.submissionTime)
          }))
          setExamineesList(rows)
          setLoading(false)
        }
      )
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        setLoading(false)
        throw e
      })
  }, [id, fetchWithUser, enqueueSnackbar])

  const getTestTrakMissQNList = useCallback(() => {
    fetchWithUser(
      `${config.apiUrl}/Examination/GetExamCycTypeListThatMissQNByExamCycId/${id}`
    )
      .then(res => res.json())
      .then((data: ExaminationCycleType[]) => {
        setTestTrakMissQNList(data)
      })
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again later', {
          variant: 'error'
        })
        throw e
      })
  }, [id, fetchWithUser, enqueueSnackbar])

  const initialFilterModel = useCallback(() => {
    if (!isExaminationTypeListLoading) {
      const trackName =
        examinationTypeList.find(
          e => e.examinationTypeID.toString() === trackId
        )?.examinationTypeShortName || ''
      setFilterModel(prev => {
        const newFilterModel = {
          ...prev,
          items: statusId
            ? changeFilterData(
                prev.items,
                GridColumnExaminess.SubmissionStatus,
                +statusId,
                'is'
              )
            : prev.items
        }
        return {
          ...newFilterModel,
          items: changeFilterData(
            newFilterModel.items,
            GridColumnExaminess.Track,
            trackName,
            'contains'
          )
        }
      })
    }
  }, [examinationTypeList, trackId, statusId, isExaminationTypeListLoading])

  useEffect(() => {
    getExamineesList()
    getTestTrakMissQNList()
  }, [getExamineesList, getTestTrakMissQNList])
  useEffect(initialFilterModel, [initialFilterModel])

  const updateScore = (params: GridCellEditStopParams, event: any) => {
    fetchWithUser(
      `${config.apiUrl}/Examination/UpdateEmpToExamCycType/${params.row.employeeToExaminationCycleTypeID}`,
      {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          ...params.row,
          score: +event.target.value,
          examinationCycleType: null
        })
      }
    ).catch(e => {
      enqueueSnackbar('Internal server error, Please try again later', {
        variant: 'error'
      })
      throw e
    })
  }

  return (
    <>
      <Stack spacing={3} height="calc(100% - 84px)">
        <DataGridPro
          apiRef={apiRef}
          rows={examineesList}
          columns={examineeColumns}
          experimentalFeatures={{newEditingApi: true}}
          autoPageSize
          headerHeight={47}
          rowHeight={46}
          density="compact"
          getRowId={row => row.employeeToExaminationCycleTypeID}
          loading={loading || !initialState}
          filterModel={filterModel}
          onFilterModelChange={newValue => setFilterModel(newValue)}
          onCellEditStop={updateScore}
          components={{
            Toolbar: TestExamineeToolbar,
            Footer: () => (
              <DataGridTotalRows
                amount={gridFilteredSortedRowIdsSelector(apiRef).length}
              />
            )
          }}
          componentsProps={{
            toolbar: {
              trackFilterToolbarProps: {
                trackList: examinationTypeList,
                selectedTrackName:
                  filterModel.items.find(x => x.columnField === 'track')
                    ?.value || 0,
                setFilterModel: setFilterModel,
                urlForChangeModel: `/shl/tests/${id}/examinees`,
                selectedStatus:
                  filterModel.items.find(
                    x => x.columnField === GridColumnExaminess.SubmissionStatus
                  )?.value || 0,
                placeholderSearch: 'Search Examinee'
              },
              cuiToolbarProps: {
                screenId: TestScreenEnum.Examinees,
                exportState: apiRef.current.exportState,
                columnVisibilityModel:
                  apiRef.current.state?.columns.columnVisibilityModel,
                csvFileName: `testNumber${examinationOrder ?? ''}Examinees`
              }
            }
          }}
          sx={{
            '.css-yrdy0g-MuiDataGrid-columnHeaderRow': {
              background: '#F4F6F8',
              borderRadius: 1
            },
            '.MuiDataGrid-withBorder': {
              borderRight: 'none'
            },
            '.MuiDataGrid-footerContainer': {
              minHeight: 36
            }
          }}
        />
      </Stack>
      <TestExamineeModal
        onCancelClick={() => setIsOpenAddExamineeDialog(false)}
        isOpen={isOpenAddExamineeDialog}
        testId={+id!}
        testOrderNumber={examinationOrder}
        setExamineeList={apiRef.current.updateRows}
        getExamineeById={(empId: number) => apiRef.current.getRow(empId)}
        getExamineesList={getExamineesList}
        testTrakMissQNList={testTrakMissQNList}
      />
    </>
  )
}
